import { ObjectTypesEnum } from "./enums/ObjectTypesEnum";
import { IObjectType } from "./types/IObjectType";

export default class ObjectType implements IObjectType {
    private static mapValueToName = {
        [ObjectTypesEnum.Report]: "Report",
        [ObjectTypesEnum.Dashboard]: "Dashboard",
        [ObjectTypesEnum.Drilldown]: "Drill Down",
    };
    private _objectType: ObjectTypesEnum;

    static getListOfStatusesForFilter() {
        return [
            this.mapValueToName[ObjectTypesEnum.Report],
            this.mapValueToName[ObjectTypesEnum.Dashboard],
            this.mapValueToName[ObjectTypesEnum.Drilldown],
        ];
    }

    static getValueFromName(name: string) {
        const entry = Object.entries(this.mapValueToName).find(it => name === it[1]);
        if (entry) {
            return Number(entry[0]) as ObjectTypesEnum;
        }

        return undefined;
    }

    constructor(objectType: ObjectTypesEnum) {
        this._objectType = objectType;
    }

    getObjectTypeName() {
        const value = ObjectType.mapValueToName[this._objectType];

        if (value === undefined) {
            return "Unknown type";
        }

        return value;
    }

    isDashboard() {
        return this._objectType === ObjectTypesEnum.Dashboard;
    }
}
