import Typography from "@material-ui/core/Typography";
import React from "react";

type Props = {
    label: string;
    content: string;
};

export default class LabelString extends React.Component<Props> {
    render() {
        const { label, content } = this.props;
        return (
            <Typography variant="body2" gutterBottom>
                <Typography variant="subtitle2" display="inline" component="span">
                    {label}:{" "}
                </Typography>
                {content}
            </Typography>
        );
    }
}
