import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react";
import React from "react";

import { mobxConnect, MobxConnectedComponent } from "../../../../mobxConnector";
import { SignInState } from "../../models/enums/SignInState";
import IdentityStore from "../../store";
import { AuthUserFormData, SignInForm, TwoFactorForm, VerificationCodeFormData } from "./Forms";
import style from "./style";
import { removeSpacesFromVerificationCode } from "./utils";

type Props = WithStyles<typeof style>;

type Stores = {
    identityStore: IdentityStore;
};

@mobxConnect<Stores>("identityStore")
@observer
class AuthPage extends MobxConnectedComponent<Stores, Props> {
    get store() {
        return this.stores.identityStore;
    }

    render() {
        const { classes } = this.props;

        return <div className={classes.root}>{this._renderForm()}</div>;
    }

    private _renderForm = () => {
        const { currentSignInState } = this.store;

        switch (currentSignInState) {
            case SignInState.SignIn:
                return <SignInForm onSubmit={this._onSignIn} />;
            case SignInState.TwoFactor:
                return <TwoFactorForm onSubmit={this._onTwoFactorAuth} />;
            case SignInState.Done:
                return null;
        }
    };

    private _onSignIn = ({ email, password }: AuthUserFormData) => {
        return new Promise(resolve =>
            this.store
                .loginOnFirstFactorAsync(email, password)
                .then(() => {
                    resolve();
                })
                .catch(({ message }) => {
                    resolve({ [FORM_ERROR]: message });
                }),
        );
    };

    private _onTwoFactorAuth = ({ verificationCode }: VerificationCodeFormData) => {
        return new Promise(resolve =>
            this.store
                .loginOnSecondFactorAsync(removeSpacesFromVerificationCode(verificationCode))
                .then(() => resolve())
                .catch(({ message }) => resolve({ [FORM_ERROR]: message })),
        );
    };
}

export default withStyles(style)(AuthPage);
