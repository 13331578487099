import React from "react";
import { RouteProps, Redirect, Route } from "react-router-dom";
import { observer } from "mobx-react";
import { mobxConnect, MobxConnectedComponent } from "../../mobxConnector";
import { IdentityStore } from "../../modules/Identity";
import { PAGES_URLS } from "../../config";

type Stores = {
    identityStore: IdentityStore;
};

@mobxConnect<Stores>("identityStore")
@observer
export default class ProtectedRoute extends MobxConnectedComponent<Stores, RouteProps> {
    render() {
        const { userInfo } = this.stores.identityStore;

        if (!userInfo) {
            return <Redirect to={PAGES_URLS.auth} />;
        }

        return <Route {...this.props} />;
    }
}
