import BulkExportMethod from "./BulkExportMethod";
import ExportTaskStatus from "./ExportTaskStatus";
import Format from "./Format";
import Frequency from "./Frequency";
import { Locale } from "./Locales";
import ObjectType from "./ObjectType";
import { IBulkExportMethod } from "./types/IBulkExportMethod";
import { IExportTask } from "./types/IExportTask";
import { IExportTaskServ } from "./types/IExportTaskServ";
import { IExportTaskStatus } from "./types/IExportTaskStatus";
import { IFormat } from "./types/IFormat";
import { IFrequency } from "./types/IFrequency";
import { ILocale } from "./types/ILocale";
import { IObjectType } from "./types/IObjectType";

export default class ExportTask implements IExportTask {
    key: number;
    userKey: number;
    login: string;
    hospitalKey: number;
    date: Date;
    status: IExportTaskStatus;
    format: IFormat;
    subscriptionKey?: number;
    frequency: IFrequency;
    locale: ILocale;
    objectType: IObjectType;
    name: string;
    selectedTimepoints: string[];
    isEnableFilterSelection: boolean;
    dimensionKey?: number;
    dimensionElementsKeys?: number[];
    attemptsCount: number;
    bulkExportMethod?: IBulkExportMethod;
    isExportToResponse?: boolean;
    isCopyEmails?: boolean;
    groupHospital?: number;
    lastSuccessfulStatus: IExportTaskStatus;
    settings: string;
    expectedTime?: Date;
    queueName?: string;
    sentEmailTime?: Date;

    constructor(servTask: IExportTaskServ) {
        const { exportSettings } = servTask.fSettings;
        this.key = servTask.fKey;
        this.login = servTask.fLogin;
        this.userKey = servTask.fUserKey;
        this.hospitalKey = servTask.fHospitalKey;
        this.date = new Date(servTask.fDate);
        this.status = new ExportTaskStatus(servTask.fStatus);
        this.format = new Format(exportSettings.format);
        this.subscriptionKey = servTask.fSubscr || undefined;
        this.frequency = new Frequency(servTask.fFrequency);
        this.locale = new Locale(servTask.fLocale);
        this.objectType = new ObjectType(servTask.fObjectKeyType);
        this.name = servTask.fName;
        this.isEnableFilterSelection = exportSettings.filterSelection;
        this.dimensionKey = exportSettings.dimkey;
        this.dimensionElementsKeys = exportSettings.selection;
        this.attemptsCount = servTask.fAttempts;
        this.bulkExportMethod =
            (exportSettings.dimkey !== undefined && new BulkExportMethod(exportSettings.bulkExportMethod)) || undefined;
        this.isExportToResponse = (exportSettings.dimkey !== undefined && exportSettings.byResponsible) || undefined;
        this.isCopyEmails = (exportSettings.dimkey !== undefined && exportSettings.sendMeCopies) || undefined;
        this.groupHospital =
            servTask.fGroupHospital === -1 || !servTask.fGroupHospital ? undefined : servTask.fGroupHospital;
        this.lastSuccessfulStatus = new ExportTaskStatus(servTask.fLastSuccessfulStatus);
        if (exportSettings.dates) {
            this.selectedTimepoints = exportSettings.dates.filter(it => it.selected).map(it => it.title);
        } else {
            this.selectedTimepoints = [];
        }
        this.settings = servTask.settings;
        this.expectedTime = servTask.expectedTime ? new Date(servTask.expectedTime) : undefined;
        this.queueName = servTask.queueName;
        this.sentEmailTime = servTask.sentEmailTime ? new Date(servTask.sentEmailTime) : undefined;
    }
}
