import { ISubscription } from "./types/ISubscription";
import { ISubscriptionServ } from "./types/ISubscriptionServ";

export default class Subscription implements ISubscription {
    key: number;
    name: string;

    constructor(sub: ISubscriptionServ) {
        this.key = sub.fKey;
        this.name = sub.fName;
    }
}
