import Button from "@material-ui/core/Button/Button";
import Divider from "@material-ui/core/Divider/Divider";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import * as React from "react";
import { Field, Form, FormRenderProps } from "react-final-form";

import InputField from "../../../../../components/InputField";
import style from "./style";
import { FormValidateErrors } from "../../../models/types/FormValidateErrors";
import { AuthUserFormData } from "./types";

type Props = {
    onSubmit: (values: AuthUserFormData) => void;
} & WithStyles<typeof style>;

class AuthUserForm extends React.Component<Props> {
    render() {
        const { onSubmit } = this.props;
        return <Form<AuthUserFormData> onSubmit={onSubmit} validate={this.validator} render={this.renderForm} />;
    }

    renderForm = (props: FormRenderProps<AuthUserFormData>) => {
        const { submitError, handleSubmit } = props;
        const { classes } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Field name="email">
                    {fieldProps => (
                        <InputField {...fieldProps} label={"Login"} type="text" autoComplete="on" tabIndex={1} />
                    )}
                </Field>
                <Field name="password">
                    {fieldProps => <InputField {...fieldProps} label={"Password"} type="password" tabIndex={2} />}
                </Field>
                <div className={classes.errorContainer}>{submitError}</div>
                <Divider className={classes.hrDivider} />
                <Button className={classes.submitButton} type="submit" variant="contained" color="primary" tabIndex={3}>
                    {"Login"}
                </Button>
            </form>
        );
    };

    validator = (values: AuthUserFormData) => {
        const { email, password } = values;
        const errors: FormValidateErrors<AuthUserFormData> = {};
        if (!email) {
            errors.email = "E-mail is required.";
        }
        if (!password) {
            errors.password = "Password is required.";
        }
        return errors;
    };
}

export default withStyles(style)(AuthUserForm);
