import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ColDef, ValueFormatterParams, ValueGetterParams, RowNode } from "ag-grid-community";
import { observer } from "mobx-react";
import React from "react";

import Table from "../../../../components/Table";
import Waiter from "../../../../components/Waiter";
import { mobxConnect, MobxConnectedComponent } from "../../../../mobxConnector";
import { formatDateTime } from "../../helpers/DatesHelper";
import ExportTaskStatusChanges from "../../models/ExportTaskStatusChanges";
import { Stores } from "../../store";
import style from "./style";

type Props = {
    exportTaskKey: number;
} & WithStyles<typeof style>;

@mobxConnect<Stores>("mainStore")
@observer
class StatusChangesInfo extends MobxConnectedComponent<Stores, Props> {
    get store() {
        return this.stores.mainStore;
    }

    componentDidMount() {
        const { exportTaskKey } = this.props;
        this.store.loadExportTaskStatusChanges(exportTaskKey);
    }

    render() {
        const { loadingTaskStatusChanges, exportTaskStatusChanges } = this.store;

        if (loadingTaskStatusChanges) {
            return <Waiter />;
        }

        if (!exportTaskStatusChanges) {
            return null;
        }

        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Status changes
                </Typography>
                <div style={{ height: "calc(100% - 39px)" }}>
                    <Table
                        columns={this._getTableColumns()}
                        rowData={exportTaskStatusChanges}
                        gridOptions={{
                            suppressContextMenu: true,
                            suppressMovableColumns: true,
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }

    private _getTableColumns(): ColDef[] {
        return [
            {
                headerName: "Previous status",
                valueGetter: this._prevStatusColumnValueGetter,
                width: 193,
            },
            {
                headerName: "New status",
                valueGetter: this._newStatusColumnValueGetter,
                width: 193,
            },
            {
                headerName: "Date change",
                field: "dateChange",
                valueFormatter: this._dateChangeColumnValueFormatter,
                width: 177,
                sort: "asc",
                comparator: this._statusDateComparator,
            },
        ];
    }

    private _statusDateComparator = (
        valueA: Date,
        valueB: Date,
        nodeA: RowNode,
        nodeB: RowNode,
        isInverted: boolean,
    ) => {
        let statusA = nodeA.data.newStatus.getKey();
        while (Math.abs(statusA) < 100) {
            statusA *= 10;
        }
        let statusB = nodeB.data.newStatus.getKey();
        while (Math.abs(statusB) < 100) {
            statusB *= 10;
        }
        if (statusA >= 300 && statusB >= 300) {
            return statusA - statusB;
        }
        return valueA.getTime() - valueB.getTime();
    };

    private _prevStatusColumnValueGetter(params: ValueGetterParams) {
        const { previousStatus } = params.data as ExportTaskStatusChanges;
        return previousStatus.getTextStatus();
    }

    private _newStatusColumnValueGetter(params: ValueGetterParams) {
        const { newStatus } = params.data as ExportTaskStatusChanges;
        return newStatus.getTextStatus();
    }

    private _dateChangeColumnValueFormatter(params: ValueFormatterParams) {
        const value: Date = params.value;
        return formatDateTime(value);
    }
}

export default withStyles(style)(StatusChangesInfo);
