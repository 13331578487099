import { action, observable } from "mobx";

import ISettingsApi from "./models/ISettingsApi";
import Settings from "./models/Settings";

import type { ISettings } from "./models/Settings";

export default class Store {
    @observable settings: ISettings | undefined;
    @observable isLoadingSettings: boolean = false;
    @observable isLoadedSettings: boolean = false;

    private _api: ISettingsApi;

    constructor(api: ISettingsApi) {
        this._api = api;
    }

    @action loadSettings() {
        if (!this.isLoadedSettings && !this.isLoadingSettings) {
            this.isLoadingSettings = true;
            this._api.getSettings().then(response => {
                this.settings = new Settings(response);
                this.isLoadingSettings = false;
                this.isLoadedSettings = true;
            });
        }
    }
}
