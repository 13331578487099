import "fontsource-roboto";

import { observer, Provider } from "mobx-react";
import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import { PAGES_URLS } from "../config";
import history from "../history";
import { mobxConnect, MobxConnectedComponent } from "../mobxConnector";
import { Router as ExportTaskTrackerPage } from "../modules/ExportTaskTracker";
import { AuthPage } from "../modules/Identity";
import AppStore from "../store";
import Layout from "./Layout";
import { ProtectedPropsRoute } from "./Routes";
import Waiter from "./Waiter";

type Stores = {
    appStore: AppStore;
};

@mobxConnect<Stores>("appStore")
@observer
export class App extends MobxConnectedComponent<Stores> {
    componentDidMount() {
        this.stores.appStore.init();
    }

    render() {
        const { isInitializing, isInited, exportTaskTrackerApi, identityStore } = this.stores.appStore;

        if (isInitializing || !identityStore) {
            return <Waiter />;
        }
        if (!isInited) {
            return null;
        }

        const canDownloadResults = identityStore.userInfo ? identityStore.userInfo.is3mUser() : false;

        return (
            <Provider identityStore={identityStore}>
                <Router history={history}>
                    <Layout>
                        <Switch>
                            <ProtectedPropsRoute
                                path={PAGES_URLS.exportTaskTracker}
                                component={ExportTaskTrackerPage}
                                componentProps={{ api: exportTaskTrackerApi!, canDownloadResults }}
                            />
                            <Route path={PAGES_URLS.auth} component={AuthPage} />
                            <Redirect exact from="/" to={PAGES_URLS.exportTaskTracker} />
                        </Switch>
                    </Layout>
                </Router>
            </Provider>
        );
    }
}
