import ITokenInfo from "./interfaces/ITokenInfo";
import { TokenInfoResponse } from "./types/TokenInfoResponse";

type TokenInfoJson = {
    accessToken: string;
    refreshToken: string;
    expiresTimeStamp: number;
};

export default class TokenInfo implements ITokenInfo {
    accessToken = "";
    refreshToken = "";
    expiresTimeStamp = 0;

    static createFromResponse(response: TokenInfoResponse): TokenInfo {
        const { access_token, refresh_token, expires_in } = response;
        const tokenInfo = new TokenInfo();

        tokenInfo.accessToken = access_token;
        tokenInfo.refreshToken = refresh_token;
        tokenInfo.expiresTimeStamp = expires_in * 1000 + Date.now();

        return tokenInfo;
    }

    static createFromJson(json: string): TokenInfo {
        const { accessToken, refreshToken, expiresTimeStamp } = JSON.parse(json) as TokenInfoJson;
        const tokenInfo = new TokenInfo();

        tokenInfo.accessToken = accessToken;
        tokenInfo.refreshToken = refreshToken;
        tokenInfo.expiresTimeStamp = expiresTimeStamp;

        return tokenInfo;
    }

    isExpired() {
        return this.expiresTimeStamp < Date.now();
    }

    willExpireInMs(ms: number) {
        return this.expiresTimeStamp - Date.now() < ms;
    }

    toJSON() {
        const jsonObject: TokenInfoJson = {
            accessToken: this.accessToken,
            expiresTimeStamp: this.expiresTimeStamp,
            refreshToken: this.refreshToken,
        };

        return JSON.stringify(jsonObject);
    }
}
