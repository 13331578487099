const appId = "exportTaskTracker";

export const STORAGE_KEYS = {
    storedToken: `${appId}.storedToken`,
    gridFilterModel: `${appId}.filterModel`,
    gridSortModel: `${appId}.sortModel`,
    gridPinnedColumns: `${appId}.pinnedColumns`,
    returningUrl: `${appId}.returningUrl`,
};

export const PAGES_URLS = {
    auth: "/Auth",
    exportTaskTracker: "/ExportTaskTracker",
};
