import { extension } from "mime-types";

import { IExportTaskResult } from "./types/IExportTaskResult";
import { IExportTaskResultServ } from "./types/IExportTaskResultServ";

export default class ExportTaskResult implements IExportTaskResult {
    message: string | undefined;
    createDate: Date;
    mimeType: string;
    isHaveFile: boolean;

    constructor(exportTaskResult: IExportTaskResultServ) {
        this.message = this.getMessage(exportTaskResult.fMessage);
        this.createDate = new Date(exportTaskResult.fCreateDate);
        this.mimeType = exportTaskResult.fMime;
        this.isHaveFile = exportTaskResult.isHaveFile;
    }

    getFileExtension() {
        if (this.mimeType === "application/x-zip-compressed") {
            return "zip";
        }
        return extension(this.mimeType) || undefined;
    }

    getMessage(sourceMessage: string): string | undefined {
        if (!sourceMessage || sourceMessage.toLowerCase() === "ok") {
            return undefined;
        }

        return sourceMessage
            .replace(/\\\\/g, "\\")
            .replace(/\\"/g, "")
            .replace(/"/g, "")
            .replace(/\\r\\n/g, "\n")
            .replace(/\\n/g, "\n")
            .replace(/\s+at\s+/g, "\n\tat ");
    }
}
