export enum StatusesEnum {
    Ready = 1,
    ReadyForExport = 2,
    PreCreateGetExpectedTime = 20,
    ExportClientProcess = 211,
    ExportClientFailed = 212,
    ExportClientSuccess = 220,
    ExportFileProcess = 221,
    ExportFileFailed = 222,
    ExportFileSuccess = 230,
    ExportSuccess = 3,
    SendResultProcess = 301,
    SendResultFailed = 302,
    SendResultSuccess = 310,
    ExportSended = 4,
    Failed = -1,
}
