import { FrequenciesEnum } from "./enums/FrequenciesEnum";
import { IFrequency } from "./types/IFrequency";

export default class Frequency implements IFrequency {
    private _frequency: FrequenciesEnum;

    constructor(frequency: FrequenciesEnum) {
        this._frequency = frequency;
    }

    getFrequencyName() {
        switch (this._frequency) {
            case FrequenciesEnum.Month:
                return "Months";
            case FrequenciesEnum.Quarter:
                return "Quarters";
            case FrequenciesEnum.Semesters:
                return "Semesters";
            case FrequenciesEnum.Year:
                return "Years";
            default:
                return `Unknown frequency (key: ${this._frequency})`;
        }
    }
}
