import { inject as _inject } from "mobx-react";
import React from "react";

export function mobxConnect<Stores extends {}>(...stores: (keyof Stores)[]) {
    return _inject(...(stores as string[]));
}

export class MobxConnectedComponent<Stores = {}, Props = {}, State = {}> extends React.Component<Props, State> {
    public get stores() {
        return (this.props as any) as Stores;
    }
}
