import React from "react";
import { observer } from "mobx-react";
import { mobxConnect, MobxConnectedComponent } from "../../../../mobxConnector";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import { IdentityStore } from "../..";
import style from "./style";
import { Logout, Login } from "../../../../icons";
import Typography from "@material-ui/core/Typography/Typography";

type Props = WithStyles<typeof style>;

type Stores = {
    identityStore: IdentityStore;
};

@mobxConnect<Stores>("identityStore")
@observer
class ToolbarAuthPanel extends MobxConnectedComponent<Stores, Props> {
    get store() {
        return this.stores.identityStore;
    }

    render() {
        const { classes } = this.props;
        const { userInfo, onToolbarButtonClick } = this.store;
        const isLoggedIn = !!userInfo;

        return (
            <div className={classes.root}>
                <Tooltip title={isLoggedIn ? "Logout" : "Login"} key="login" PopperProps={{ disablePortal: true }}>
                    <IconButton onClick={onToolbarButtonClick}>{isLoggedIn ? <Logout /> : <Login />}</IconButton>
                </Tooltip>
                {userInfo && <Typography className={classes.text}>{userInfo.name}</Typography>}
            </div>
        );
    }
}

export default withStyles(style)(ToolbarAuthPanel);
