import _ from "lodash";

import { FilterTypesEnum } from "./enums/FilterTypesEnum";
import { SortDirectionsEnum } from "./enums/SortDirectionsEnum";
import FilterSetMapping from "./FilterSetMapping";
import { IFilterColumnParams } from "./types/IFilterColumnParams";
import { IRequestFilter } from "./types/IRequestFilter";
import { FilterType, IRequestFilterParams } from "./types/IRequestFilterParams";
import { IRequestFilterParamsServ } from "./types/IRequestFilterParamsServ";
import { ISortColumnParams } from "./types/ISortColumnParams";

export default class RequestFilter implements IRequestFilter {
    private _offsetRows: number;
    private _countRows: number;
    private _sortColumns: ISortColumnParams[];
    private _filterColumns: IFilterColumnParams[];

    constructor(params: IRequestFilterParams) {
        const { endRow, startRow, sortModel, filterModel } = params;
        this._offsetRows = startRow;
        this._countRows = endRow - startRow;
        this._sortColumns = sortModel.map(it => ({
            columnId: it.colId,
            sortDirection: it.sort === "desc" ? SortDirectionsEnum.Desc : SortDirectionsEnum.Asc,
        }));
        this._filterColumns = Object.entries(filterModel).map(([colId, model]) => {
            const filterType = this._getFilterType(model.type);
            const filterColumnParams: IFilterColumnParams = {
                columnId: colId,
                type: filterType,
            };

            switch (model.filterType) {
                case "text":
                    filterColumnParams.valueString = model.filter as string;
                    break;
                case "number":
                    if (filterType === FilterTypesEnum.InRange) {
                        filterColumnParams.valueNumberFrom = model.filter as number;
                        filterColumnParams.valueNumberTo = model.filterTo;
                    } else {
                        filterColumnParams.valueNumber = model.filter as number;
                    }
                    break;
                case "date":
                    if (filterType === FilterTypesEnum.InRange) {
                        filterColumnParams.valueDateFrom = this._createDateAsUTC(new Date(model.dateFrom!));
                        filterColumnParams.valueDateTo = this._createDateAsUTC(new Date(model.dateTo!));
                    } else {
                        filterColumnParams.valueDate = this._createDateAsUTC(new Date(model.dateFrom!));
                    }
                    break;
                case "set":
                    const values = model
                        .values!.map(value => FilterSetMapping.getValueFromFilter(colId, value))
                        .filter(it => _.isNumber(it) || _.isString(it)) as number[] | string[];
                    const isNumberValues = values.every((it: string | number) => _.isNumber(it));
                    if (isNumberValues) {
                        filterColumnParams.setValuesNumbers = values as number[];
                    } else {
                        filterColumnParams.setValuesStrings = values as string[];
                    }
            }

            return filterColumnParams;
        });
    }

    getServObj() {
        const servObj: IRequestFilterParamsServ = {
            countRows: this._countRows,
            offsetRows: this._offsetRows,
            sortColumns: this._sortColumns.length > 0 ? this._sortColumns : undefined,
            filterColumns: this._filterColumns.length > 0 ? this._filterColumns : undefined,
        };
        return servObj;
    }

    private _getFilterType(type: FilterType): FilterTypesEnum {
        switch (type) {
            case "equals":
                return FilterTypesEnum.Equal;
            case "notEqual":
                return FilterTypesEnum.NotEqual;
            case "contains":
                return FilterTypesEnum.Contains;
            case "notContains":
                return FilterTypesEnum.NotContains;
            case "startsWith":
                return FilterTypesEnum.StartsWith;
            case "endsWith":
                return FilterTypesEnum.EndsWith;
            case "greaterThan":
                return FilterTypesEnum.GreaterThan;
            case "greaterThanOrEqual":
                return FilterTypesEnum.GreaterThanOrEquals;
            case "lessThan":
                return FilterTypesEnum.LessThan;
            case "lessThanOrEqual":
                return FilterTypesEnum.LessThanOrEquals;
            case "inRange":
                return FilterTypesEnum.InRange;
        }
    }

    private _createDateAsUTC(date: Date) {
        return new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
            ),
        );
    }
}
