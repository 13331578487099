import DictionaryManager from "../../DictionaryManager";
import { SUBSCRIPTION_DICTIONARY_ID } from "../constants";
import { SubscriptionsDictionary } from "../models/types/SubscriptionsDictionary";

export function getSubscriptionNameByKey(subscriptionKey: number): string | undefined {
    const subscriptionsDictionary = DictionaryManager.getDictionary<SubscriptionsDictionary>(
        SUBSCRIPTION_DICTIONARY_ID,
    );
    if (!subscriptionsDictionary) {
        return undefined;
    }
    const subscription = subscriptionsDictionary[subscriptionKey];
    if (!subscription) {
        return undefined;
    }
    return subscription.name;
}
