import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react";
import React from "react";

import Waiter from "../../../../components/Waiter";
import { mobxConnect, MobxConnectedComponent } from "../../../../mobxConnector";
import { getGroupNameByKey } from "../../helpers/GroupsHelper";
import { getHospitalNameByKey } from "../../helpers/HospitalsHelper";
import { getSubscriptionNameByKey } from "../../helpers/SubscriptionsHelper";
import { Stores } from "../../store";
import LabelString from "./LabelString";

type Props = {
    exportTaskKey: number;
};

@mobxConnect<Stores>("mainStore")
@observer
export default class GeneralInfo extends MobxConnectedComponent<Stores, Props> {
    get store() {
        return this.stores.mainStore;
    }

    componentDidMount() {
        const { exportTaskKey } = this.props;
        this.store.loadTask(exportTaskKey);
        this.store.loadGroups();
        this.store.loadHospitals();
        this.store.loadSubscriptions();
    }

    render() {
        const { exportTask, loadingTasks, loadingGroups, loadingHospitals, loadingSubscriptions } = this.store;

        if (loadingTasks || loadingGroups || loadingHospitals || loadingSubscriptions) {
            return <Waiter />;
        }

        if (!exportTask) {
            return null;
        }

        const subscriptionName = exportTask.subscriptionKey
            ? getSubscriptionNameByKey(exportTask.subscriptionKey) || exportTask.subscriptionKey.toString()
            : undefined;

        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    General Information
                </Typography>
                <LabelString label="Task key" content={exportTask.key.toString()} />
                <LabelString label="Task name" content={exportTask.name} />
                <LabelString label="Login" content={exportTask.login} />
                <LabelString
                    label="Hospital"
                    content={`${getHospitalNameByKey(exportTask.hospitalKey)} (${exportTask.hospitalKey})`}
                />
                {exportTask.groupHospital && (
                    <LabelString
                        label="Hospitals Group"
                        content={`${getGroupNameByKey(exportTask.groupHospital)} (${exportTask.groupHospital})`}
                    />
                )}
                <LabelString label="Format" content={exportTask.format.getFormatName()} />
                {subscriptionName && <LabelString label="Subscription" content={subscriptionName} />}
                <LabelString label="Locale" content={exportTask.locale.getLocaleName()} />
                <LabelString label="Type" content={exportTask.objectType.getObjectTypeName()} />
                <LabelString label="Frequency" content={exportTask.frequency.getFrequencyName()} />
                <LabelString label="Timepoints" content={exportTask.selectedTimepoints.join(", ")} />
            </React.Fragment>
        );
    }
}
