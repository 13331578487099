import { ColumnPinnedEvent, FilterChangedEvent, GridReadyEvent, SortChangedEvent } from "ag-grid-community";
import React from "react";

import Table from "../../../../components/Table";
import getColumns from "./columns";
import tableSettings from "./tableSettings";

type Props = {
    onFilterChanged: (event: FilterChangedEvent) => void;
    onSortChanged: (event: SortChangedEvent) => void;
    onColumnPinned: (event: ColumnPinnedEvent) => void;
    onGridReady: (event: GridReadyEvent) => void;
};

export default class TaskListTable extends React.Component<Props> {
    render() {
        const { onFilterChanged, onGridReady, onSortChanged, onColumnPinned } = this.props;
        return (
            <Table
                columns={getColumns()}
                gridOptions={tableSettings}
                onFilterChanged={onFilterChanged}
                onSortChanged={onSortChanged}
                onGridReady={onGridReady}
                onColumnPinned={onColumnPinned}
            />
        );
    }
}
