import Endpoints, { IEndpointsServ } from "./Endpoints";

export interface ISettingsServ {
    gatid: string;
    endpoints: IEndpointsServ;
    banner: {
        image: string;
        pattern: string;
    };
    updatedAt: number;
    tagNewLifetime: number;
    maxOldNotifications: number;
    inactivityTimeout: number;
    localeCookieLifetime: number;
    environment: string;
}

export interface ISettings {
    endpoints: Endpoints;
}

export default class Settings implements ISettings {
    endpoints: Endpoints;

    constructor(settings: ISettingsServ) {
        this.endpoints = new Endpoints(settings.endpoints);
    }
}
