import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react";
import React from "react";

import Waiter from "../../../../components/Waiter";
import { mobxConnect, MobxConnectedComponent } from "../../../../mobxConnector";
import { Stores } from "../../store";
import LabelString from "./LabelString";
import style from "./style";

type Props = {
    exportTaskKey: number;
} & WithStyles<typeof style>;

type State = {
    isOpenedDetailsDialog: boolean;
};

@mobxConnect<Stores>("mainStore")
@observer
class ExportInfo extends MobxConnectedComponent<Stores, Props, State> {
    get store() {
        return this.stores.mainStore;
    }

    state: State = {
        isOpenedDetailsDialog: false,
    };

    componentDidMount() {
        const { exportTaskKey } = this.props;
        this.store.loadTask(exportTaskKey);
    }

    render() {
        const { exportTask, loadingTasks } = this.store;

        if (loadingTasks) {
            return <Waiter />;
        }

        if (!exportTask) {
            return null;
        }

        const {
            dimensionKey,
            dimensionElementsKeys,
            isCopyEmails,
            isExportToResponse,
            bulkExportMethod,
            isEnableFilterSelection,
            objectType,
            settings,
        } = exportTask;
        const sendExportToResponse = isExportToResponse ? "Yes" : "No";
        const sendExportToMe = isCopyEmails ? "Yes" : "No";
        const includeFilterSelection = isEnableFilterSelection ? "Yes" : "No";
        const formattedSettings = JSON.stringify(JSON.parse(settings), undefined, 4);
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Export Details
                </Typography>
                {dimensionKey && <LabelString label="Dimension" content={dimensionKey.toString()} />}
                {dimensionElementsKeys && (
                    <LabelString label="Dimension Elements count" content={dimensionElementsKeys.length.toString()} />
                )}
                {dimensionKey && bulkExportMethod && (
                    <LabelString label="Bulk export method" content={bulkExportMethod.getBulkExportMethodName()} />
                )}
                {dimensionKey && <LabelString label="Export for resp. persons" content={sendExportToResponse} />}
                {dimensionKey && isExportToResponse && (
                    <LabelString label="Send copy for me" content={sendExportToMe} />
                )}
                {objectType.isDashboard() ? (
                    <LabelString label="Include Filter Selection" content={includeFilterSelection} />
                ) : null}
                <Button
                    color="primary"
                    variant="contained"
                    onClick={this.onClickButtonOpenDialog}
                    style={{ width: "fit-content" }}
                >
                    Open settings details
                </Button>
                <Dialog open={this.state.isOpenedDetailsDialog} onClose={this.onCloseDialog}>
                    <DialogTitle>Settings Details</DialogTitle>
                    <DialogContent>
                        <Typography variant="caption" className={this.props.classes.settings}>
                            {formattedSettings}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.onClickButtonCopySettings}
                            style={{ width: "fit-content" }}
                        >
                            Copy settings
                        </Button>
                        <Button color="primary" onClick={this.onCloseDialog} style={{ width: "fit-content" }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    onClickButtonOpenDialog = () => {
        this.setState({
            isOpenedDetailsDialog: true,
        });
    };

    onCloseDialog = () => {
        this.setState({
            isOpenedDetailsDialog: false,
        });
    };

    onClickButtonCopySettings = () => {
        this.store.copySettingsToClipboard();
    };
}

export default withStyles(style)(ExportInfo);
