import { IIdentityApi, TwoFactorParams, TokenInfoResponse, UserInfoResponse } from "../modules/Identity";
import { AxiosInstance } from "axios";

type SignInResponse = {
    preAuthToken: string;
    canSelectRoles: boolean;
};
type GetRolesResponse = {
    roles: RoleForSelect[];
};
type Get2FactorParamsResponse = {
    _2FaParams: TwoFactorParams;
};
type RoleForSelect = {
    key: string;
    name: string;
};

export default class IdentityApi implements IIdentityApi {
    private _identityInstanceApi: AxiosInstance;
    private _preAuthToken: string | undefined;

    constructor(identityInstanceApi: AxiosInstance) {
        this._identityInstanceApi = identityInstanceApi;
    }

    signIn(email: string, password: string) {
        return this._identityInstanceApi
            .post<SignInResponse>(`/Account/SignIn`, { email, password })
            .then(resp => {
                const { preAuthToken, canSelectRoles } = resp.data;
                this._preAuthToken = preAuthToken;
                return canSelectRoles;
            })
            .catch(reason => {
                throw reason.response.data;
            });
    }

    getRoles(hospitalKey: number) {
        return this._identityInstanceApi
            .post<GetRolesResponse>(
                `/Account/GetRoles`,
                { hospitalKey },
                { headers: { PreAuthToken: this._preAuthToken } },
            )
            .then(resp => resp.data.roles.map(({ key }) => key))
            .catch(reason => {
                throw reason.response.data;
            });
    }

    getTwoFactorParams(hospitalKey: number, selectedRole?: string) {
        return this._identityInstanceApi
            .post<Get2FactorParamsResponse>(
                "/Account/GetTwoFactorParams",
                {
                    hospitalKey,
                    selectedRole,
                },
                { headers: { PreAuthToken: this._preAuthToken } },
            )
            .then(resp => resp.data._2FaParams)
            .catch(reason => {
                throw reason.response.data;
            });
    }

    refreshToken(refreshToken: string) {
        const formData: FormData = new FormData();
        formData.append("client_id", "web");
        formData.append("grant_type", "refresh_token");
        formData.append("refresh_token", refreshToken);

        return this._identityInstanceApi
            .post<TokenInfoResponse>(`/connect/token`, formData)
            .then(resp => resp.data)
            .catch(reason => {
                throw reason.response.data;
            });
    }

    generateToken(hospitalKey: number, hospitalGroupKey: number, selectedRole?: string) {
        return this._identityInstanceApi
            .post<TokenInfoResponse>(
                `/Account/GenerateToken`,
                {
                    hospitalKey,
                    hospitalGroupKey,
                    selectedRole,
                },
                { headers: { PreAuthToken: this._preAuthToken } },
            )
            .then(resp => {
                this._preAuthToken = undefined;
                return resp.data;
            })
            .catch(reason => {
                throw reason.response.data;
            });
    }

    loginTwoFactor(hospitalKey: number, hospitalGroupKey: number, verificationCode: string, selectedRole?: string) {
        return this._identityInstanceApi
            .post<TokenInfoResponse>(
                "/TwoFactor/Login",
                {
                    verificationCode,
                    hospitalKey,
                    hospitalGroupKey,
                    selectedRole,
                },
                { headers: { PreAuthToken: this._preAuthToken } },
            )
            .then(resp => {
                this._preAuthToken = undefined;
                return resp.data;
            })
            .catch(reason => {
                throw reason.response.data;
            });
    }

    getUserInfo(authToken: string) {
        return this._identityInstanceApi
            .get<UserInfoResponse>(`/connect/userinfo`, {
                headers: {
                    ...(authToken && {
                        Authorization: `Bearer ${authToken}`,
                    }),
                },
            })
            .then(resp => resp.data)
            .catch(reason => {
                throw reason.response.data;
            });
    }

    signOut(token: string) {
        const formData: FormData = new FormData();
        formData.append("token", token);
        formData.append("token_type_hint", "access_token");
        formData.append("client_id", "web");

        return this._identityInstanceApi
            .post<boolean>(`/connect/revocation`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
    }
}
