import CircularProgress from "@material-ui/core/CircularProgress";
import { observer } from "mobx-react";
import React from "react";

import { mobxConnect, MobxConnectedComponent } from "../../../../mobxConnector";
import { Stores } from "../../store";
import TaskListTable from "../TaskListTable";

@mobxConnect<Stores>("mainStore")
@observer
class TasksListPage extends MobxConnectedComponent<Stores> {
    componentDidMount() {
        const { loadLogins, loadHospitals, loadSubscriptions } = this.stores.mainStore;
        loadLogins();
        loadHospitals();
        loadSubscriptions();
    }
    render() {
        const {
            loadingHospitals,
            loadingLogins,
            loadingSubscriptions,
            loadedHospitals,
            loadedLogins,
            loadedSubscriptions,
            onFilterChanged,
            onGridReady,
            onSortChanged,
            onColumnPinned,
        } = this.stores.mainStore;
        if (loadingHospitals || loadingLogins || loadingSubscriptions) {
            return <CircularProgress />;
        }

        if (!loadedLogins || !loadedHospitals || !loadedSubscriptions) {
            return null;
        }

        return (
            <TaskListTable
                onFilterChanged={onFilterChanged}
                onGridReady={onGridReady}
                onSortChanged={onSortChanged}
                onColumnPinned={onColumnPinned}
            />
        );
    }
}

export default TasksListPage;
