import { AxiosInstance } from "axios";

import {
    IExportTaskResultServ,
    IExportTaskServ,
    IExportTaskStatusChangesServ,
    IExportTaskTrackerApi,
    IGroupServ,
    IHospitalServ,
    IRequestFilter,
    ISubscriptionServ,
} from "../modules/ExportTaskTracker";

type ExportTaskTrackerApiParams = {
    baseInstanceApi: AxiosInstance;
    identityInstanceApi: AxiosInstance;
    exportManagerInstanceApi: AxiosInstance;
};

export default class ExportTaskTrackerApi implements IExportTaskTrackerApi {
    private _baseInstanceApi: AxiosInstance;
    private _identityInstanceApi: AxiosInstance;
    private _exportManagerInstanceApi: AxiosInstance;

    constructor(params: ExportTaskTrackerApiParams) {
        this._baseInstanceApi = params.baseInstanceApi;
        this._identityInstanceApi = params.identityInstanceApi;
        this._exportManagerInstanceApi = params.exportManagerInstanceApi;
    }

    getLogins() {
        return this._identityInstanceApi.get<string[]>("/Users/GetLogins").then(resp => resp.data);
    }

    getExportTasks(filter: IRequestFilter): Promise<{ exportTasks: IExportTaskServ[]; countRows: number }> {
        return this._exportManagerInstanceApi
            .post<{ exportTasks: IExportTaskServ[]; countRows: number }>("/process/GetExportTasks", filter.getServObj())
            .then(resp => resp.data);
    }

    getExportTask(exportTaskKey: number): Promise<IExportTaskServ> {
        return this._exportManagerInstanceApi
            .get<IExportTaskServ>(`/process/GetExportTask/${exportTaskKey}`)
            .then(resp => resp.data);
    }

    getExportTaskResult(exportTaskKey: number): Promise<IExportTaskResultServ> {
        return this._exportManagerInstanceApi
            .get<IExportTaskResultServ>(`process/GetExportTaskResult/${exportTaskKey}`)
            .then(resp => resp.data);
    }

    downloadExportResult(exportTaskKey: number): Promise<string> {
        return this._exportManagerInstanceApi
            .get<string>(`process/DownloadExportResult/${exportTaskKey}`, {
                responseType: "blob",
            })
            .then(resp => resp.data);
    }

    getExportTaskStatusChanges(exportTaskKey: number): Promise<IExportTaskStatusChangesServ[]> {
        return this._exportManagerInstanceApi
            .get<IExportTaskStatusChangesServ[]>(`process/GetExportTaskStatusChanges/${exportTaskKey}`)
            .then(resp => resp.data);
    }

    getSubscriptions(): Promise<ISubscriptionServ[]> {
        return this._exportManagerInstanceApi
            .get<ISubscriptionServ[]>("/process/GetSubscriptions")
            .then(resp => resp.data);
    }

    getHospitalsData(): Promise<IHospitalServ[]> {
        return this._baseInstanceApi.get<IHospitalServ[]>("/api/Hospitals/GetHospitalsData").then(resp => resp.data);
    }

    getGroupsData(): Promise<IGroupServ[]> {
        return this._baseInstanceApi.get<IGroupServ[]>("/api/Hospitals/GetGroupsData").then(resp => resp.data);
    }
}
