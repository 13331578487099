import { ColumnApi, ColumnPinnedEvent, FilterChangedEvent, GridApi, GridReadyEvent, IGetRowsParams, SortChangedEvent } from "ag-grid-community";
import * as clipboard from "clipboard-polyfill";
import { saveAs } from "file-saver";
import { action, observable } from "mobx";

import { STORAGE_KEYS } from "../../config";
import DictionaryManager from "../DictionaryManager";
import { GROUPS_DICTIONARY_ID, HOSPITALS_DICTIONARY_ID, LOGINS_DICTIONARY_ID, SUBSCRIPTION_DICTIONARY_ID } from "./constants";
import ExportTask from "./models/ExportTask";
import ExportTaskResult from "./models/ExportTaskResult";
import ExportTaskStatusChanges from "./models/ExportTaskStatusChanges";
import Group from "./models/Group";
import Hospital from "./models/Hospital";
import RequestFilter from "./models/RequestFilter";
import Subscription from "./models/Subscription";
import { GroupsDictionary } from "./models/types/GroupsDictionary";
import { HospitalsDictionary } from "./models/types/HospitalsDictionary";
import { IExportTask } from "./models/types/IExportTask";
import { IExportTaskResult } from "./models/types/IExportTaskResult";
import { IExportTaskStatusChanges } from "./models/types/IExportTaskStatusChanges";
import { IExportTaskTrackerApi } from "./models/types/IExportTaskTrackerApi";
import { PinnedColumns } from "./models/types/PinnedColumns";
import { SubscriptionsDictionary } from "./models/types/SubscriptionsDictionary";
import TableDataSource from "./tableDataSource";

export default class Store {
    @observable loadingTasks: boolean = false;
    @observable loadingHospitals: boolean = false;
    @observable loadedHospitals: boolean = false;
    @observable loadingGroups: boolean = false;
    @observable loadedGroups: boolean = false;
    @observable loadingLogins: boolean = false;
    @observable loadedLogins: boolean = false;
    @observable loadingSubscriptions: boolean = false;
    @observable loadedSubscriptions: boolean = false;
    @observable loadingTaskResult: boolean = false;
    @observable loadingTaskStatusChanges: boolean = false;

    @observable exportTask: IExportTask | undefined;
    @observable exportTaskResult: IExportTaskResult | undefined;
    @observable exportTaskStatusChanges: IExportTaskStatusChanges[] | undefined;

    private _api: IExportTaskTrackerApi;

    constructor(api: IExportTaskTrackerApi) {
        this._api = api;
    }

    @action loadTasks = (params: IGetRowsParams) => {
        const requestFilter = new RequestFilter(params);
        this._api.getExportTasks(requestFilter).then(({ countRows, exportTasks }) => {
            const tasks = exportTasks.map(it => new ExportTask(it));
            params.successCallback(tasks, countRows);
        });
    };

    @action loadTask = (exportTaskKey: number) => {
        if (!this.loadingTasks) {
            this.loadingTasks = true;
            this._api
                .getExportTask(exportTaskKey)
                .then(exportTask => {
                    this.exportTask = new ExportTask(exportTask);
                })
                .finally(() => {
                    this.loadingTasks = false;
                });
        }
    };

    @action loadTaskResult = (exportTaskKey: number) => {
        if (!this.loadingTaskResult) {
            this.loadingTaskResult = true;
            this._api
                .getExportTaskResult(exportTaskKey)
                .then(exportTaskResult => {
                    this.exportTaskResult = new ExportTaskResult(exportTaskResult);
                })
                .finally(() => {
                    this.loadingTaskResult = false;
                });
        }
    };

    @action downloadTaskResult = (exportTaskKey: number) => {
        this._api.downloadExportResult(exportTaskKey).then(file => {
            const blob = new Blob([file]);
            const fileName = `${this.exportTask?.name}.${this.exportTaskResult?.getFileExtension()}`;
            saveAs(blob, fileName);
        });
    };

    @action loadExportTaskStatusChanges = (exportTaskKey: number) => {
        if (!this.loadingTaskStatusChanges) {
            this.loadingTaskStatusChanges = true;
            this._api
                .getExportTaskStatusChanges(exportTaskKey)
                .then(
                    statusChanges =>
                        (this.exportTaskStatusChanges = statusChanges.map(it => new ExportTaskStatusChanges(it))),
                )
                .finally(() => (this.loadingTaskStatusChanges = false));
        }
    };

    @action loadHospitals = () => {
        this.loadedHospitals = DictionaryManager.getDictionary(HOSPITALS_DICTIONARY_ID) !== undefined;
        if (!this.loadedHospitals) {
            this.loadingHospitals = true;
            this._api.getHospitalsData().then(hospitals => {
                const hospitalsDict: HospitalsDictionary = {};
                hospitals.forEach(hosp => {
                    hospitalsDict[hosp.key] = new Hospital(hosp);
                });
                DictionaryManager.addDictionary(HOSPITALS_DICTIONARY_ID, hospitalsDict);
                this.loadingHospitals = false;
                this.loadedHospitals = true;
            });
        }
    };

    @action loadGroups = () => {
        this.loadedGroups = DictionaryManager.getDictionary(GROUPS_DICTIONARY_ID) !== undefined;
        if (!this.loadedGroups) {
            this.loadingGroups = true;
            this._api.getGroupsData().then(groups => {
                const groupsDict: GroupsDictionary = {};
                groups.forEach(group => {
                    groupsDict[group.key] = new Group(group);
                });
                DictionaryManager.addDictionary(GROUPS_DICTIONARY_ID, groupsDict);
                this.loadingGroups = false;
                this.loadedGroups = true;
            });
        }
    };

    @action loadLogins = () => {
        this.loadedLogins = DictionaryManager.getDictionary(LOGINS_DICTIONARY_ID) !== undefined;
        if (!this.loadedLogins) {
            this.loadingLogins = true;
            this._api.getLogins().then(logins => {
                DictionaryManager.addDictionary(LOGINS_DICTIONARY_ID, logins);
                this.loadingLogins = false;
                this.loadedLogins = true;
            });
        }
    };

    @action loadSubscriptions = (force: boolean = false) => {
        this.loadedSubscriptions = DictionaryManager.getDictionary(SUBSCRIPTION_DICTIONARY_ID) !== undefined;
        if (!this.loadedSubscriptions || force) {
            this.loadingSubscriptions = true;
            this._api.getSubscriptions().then(subscriptions => {
                const subscriptionsDict: SubscriptionsDictionary = {};
                subscriptions.forEach(subscription => {
                    const sub = new Subscription(subscription);
                    subscriptionsDict[sub.key] = sub;
                });
                DictionaryManager.addDictionary(SUBSCRIPTION_DICTIONARY_ID, subscriptionsDict, force);
                this.loadingSubscriptions = false;
                this.loadedSubscriptions = true;
            });
        }
    };

    copyMessageToClipboard() {
        if (!this.exportTaskResult) {
            return;
        }
        const { message } = this.exportTaskResult;
        const ytMessage = `\`\`\`stacktrace\n${message}\n\`\`\``;
        clipboard.writeText(ytMessage);
    }

    copySettingsToClipboard() {
        if (!this.exportTask) {
            return;
        }
        const { settings } = this.exportTask;
        clipboard.writeText(settings);
    }

    onGridReady = (event: GridReadyEvent) => {
        this._loadFilterModelFromLocalStorage(event.api);
        this._loadSortModelFromLocalStorage(event.api);
        this._loadPinnedColumnsFromLocalStorage(event.columnApi);
        event.api.sizeColumnsToFit();
        event.api.setDatasource(new TableDataSource(this));
    };

    onFilterChanged = (event: FilterChangedEvent) => {
        const filterModel = event.api.getFilterModel();
        this._saveFilterModelToLocalStorage(filterModel);
    };

    onSortChanged = (event: SortChangedEvent) => {
        const sortModel = event.api.getSortModel();
        this._saveSortModelToLocalStorage(sortModel);
    };

    onColumnPinned = (event: ColumnPinnedEvent) => {
        this._saveColumnPinnedToLocalStorage(event.column!.getColId(), event.pinned);
    };

    closeTab = () => {
        window.close();
    };

    private _saveFilterModelToLocalStorage(filterModel: { [key: string]: any }) {
        localStorage.setItem(STORAGE_KEYS.gridFilterModel, JSON.stringify(filterModel));
    }

    private _saveSortModelToLocalStorage(sortModel: { colId: string; sort: string }[]) {
        localStorage.setItem(STORAGE_KEYS.gridSortModel, JSON.stringify(sortModel));
    }

    private _saveColumnPinnedToLocalStorage(columnId: string, pinned: string | null) {
        const pinnedColumnsJson = localStorage.getItem(STORAGE_KEYS.gridPinnedColumns);
        const pinnedColumns = pinnedColumnsJson ? (JSON.parse(pinnedColumnsJson) as PinnedColumns) : {};
        if (pinned === null) {
            delete pinnedColumns[columnId];
        } else {
            pinnedColumns[columnId] = pinned;
        }

        localStorage.setItem(STORAGE_KEYS.gridPinnedColumns, JSON.stringify(pinnedColumns));
    }

    private _loadFilterModelFromLocalStorage(api: GridApi) {
        const initFilterModelJson = localStorage.getItem(STORAGE_KEYS.gridFilterModel);
        if (initFilterModelJson) {
            const initFilterModel = JSON.parse(initFilterModelJson);
            api.setFilterModel(initFilterModel);
        }
    }

    private _loadSortModelFromLocalStorage(api: GridApi) {
        const initSortModelJson = localStorage.getItem(STORAGE_KEYS.gridSortModel);
        if (initSortModelJson) {
            const initSortModel = JSON.parse(initSortModelJson);
            api.setSortModel(initSortModel);
        }
    }

    private _loadPinnedColumnsFromLocalStorage(columnApi: ColumnApi) {
        const pinnedColumnsJson = localStorage.getItem(STORAGE_KEYS.gridPinnedColumns);
        if (pinnedColumnsJson) {
            const pinnedColumns = JSON.parse(pinnedColumnsJson) as PinnedColumns;
            Object.entries(pinnedColumns).forEach(([columnId, value]) => {
                columnApi.setColumnPinned(columnId, value);
            });
        }
    }

    // private _isNeedReloadSubscriptions(exportTasks: ExportTask[]) {
    //     const subscriptionsDict = DictionaryManager.getDictionary<SubscriptionsDictionary>(SUBSCRIPTION_DICTIONARY_ID);

    //     if (!subscriptionsDict) {
    //         return true;
    //     }

    //     const subscriptionsKeysFromExportTasks = _.uniq(exportTasks.map(it => it.subscriptionKey).filter(it => !!it));
    //     const subscriptionsKeysFromDictionary = Object.keys(subscriptionsDict).map(it => Number(it));
    //     return _.difference(subscriptionsKeysFromExportTasks, subscriptionsKeysFromDictionary).length > 0;
    // }
}

export type Stores = {
    mainStore: Store;
};
