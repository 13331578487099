import { createStyles, Theme } from "@material-ui/core/styles";

export default (theme: Theme) =>
    createStyles({
        root: {
            display: "inline-block",
            position: "relative",
        },
        drawer: {
            width: 240,
            flexShrink: 0,
            whiteSpace: "nowrap",
        },
        drawerOpen: {
            width: 240,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: 100,
            }),
            overflowX: "hidden",
        },
        drawerClose: {
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: 100,
            }),
            overflowX: "hidden",
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9) + 1,
            },
        },
        button: {
            position: "absolute",
            right: -15,
            zIndex: 1201,
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: "white",
            "&:hover": {
                backgroundColor: "white",
            },
        },
    });
