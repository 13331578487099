import "./style.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { ColDef, ColGroupDef, GridOptions } from "ag-grid-community";
import { LicenseManager } from "ag-grid-enterprise";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import clsx from "clsx";
import React from "react";

type Props<T> = {
    rows?: T[];
    columns: (ColDef | ColGroupDef)[];
    gridOptions: GridOptions;
} & AgGridReactProps;

LicenseManager.setLicenseKey(
    "Smart_Analytics_Inc._Smart_Analytics_Development_Team_Multiple_Applications_3_Devs_16_Deployment_Licenses_18_December_2020_[v2]_MTYwODI0OTYwMDAwMA==83a98a7287c9ac1d1a57f8533ccd91cb",
);

export default class Table<T> extends React.Component<Props<T>> {
    render() {
        const { columns, gridOptions, ...agGridProps } = this.props;
        return (
            <div
                className={clsx("ag-theme-alpine", "ag-theme-custom")}
                style={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <AgGridReact {...agGridProps} columnDefs={columns} gridOptions={gridOptions} />
            </div>
        );
    }
}
