import DictionaryManager from "../../DictionaryManager";
import { GROUPS_DICTIONARY_ID } from "../constants";
import { GroupsDictionary } from "../models/types/GroupsDictionary";

export function getGroupNameByKey(groupKey: number): string | undefined {
    const groupsDictionary = DictionaryManager.getDictionary<GroupsDictionary>(GROUPS_DICTIONARY_ID);
    if (!groupsDictionary) {
        return undefined;
    }
    const group = groupsDictionary[groupKey];
    if (!group) {
        return undefined;
    }
    return group.name;
}
