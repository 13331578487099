import { GridOptions, RowClickedEvent } from "ag-grid-community";

import history from "../../../../history";
import ExportTask from "../../models/ExportTask";
import { defaultColumnsSettings } from "./columns";

const settings: GridOptions = {
    defaultColDef: defaultColumnsSettings,
    rowModelType: "infinite",
    maxConcurrentDatasourceRequests: 2,
    tooltipShowDelay: 500,
    onRowClicked,
    getRowNodeId: (data: ExportTask) => data.key.toString(),
    onGridReady: event => {
        event.api.sizeColumnsToFit();
    },
    onColumnResized: event => {
        event.api.resetRowHeights();
    },
};

function onRowClicked(event: RowClickedEvent) {
    const { key } = event.data as ExportTask;
    const url = `${history.location.pathname}/${key}`;
    const win = window.open(undefined, "_blank");

    if (win) {
        win.location.href = url;
    }
}

export default settings;
