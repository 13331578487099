import DictionaryManager from "../../DictionaryManager";
import { HOSPITALS_DICTIONARY_ID } from "../constants";
import { HospitalsDictionary } from "./types/HospitalsDictionary";
import { IHospital } from "./types/IHospital";
import { IHospitalServ } from "./types/IHospitalServ";

export default class Hospital implements IHospital {
    key: number;
    name: string;
    isOrganization: boolean;

    static getValueFromName(name: string) {
        const hospDictionary = DictionaryManager.getDictionary<HospitalsDictionary>(HOSPITALS_DICTIONARY_ID) || {};
        const entry = Object.entries(hospDictionary).find(it => name === it[1]!.name);
        if (entry) {
            return Number(entry[0]);
        }

        return undefined;
    }

    constructor(hospServ: IHospitalServ) {
        this.key = hospServ.key;
        this.name = hospServ.name;
        this.isOrganization = hospServ.isOrganization;
    }
}
