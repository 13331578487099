import { observer } from "mobx-react";
import React from "react";
import { Redirect, RouteProps } from "react-router-dom";

import { PAGES_URLS } from "../../config";
import { mobxConnect, MobxConnectedComponent } from "../../mobxConnector";
import AppStore from "../../store";
import PropsRoute from "./PropsRoute";

type Props<T = {}, P = {}> = {
    componentProps: T;
    component: React.ComponentType<T>;
} & Omit<RouteProps, "component">;

type Stores = {
    appStore: AppStore;
};

@mobxConnect<Stores>("appStore")
@observer
export default class ProtectedPropsRoute<T = {}, P = {}> extends MobxConnectedComponent<Stores, Props<T, P>> {
    get store() {
        return this.stores.appStore;
    }

    render() {
        if (!this.store.getToken()) {
            this.store.saveReturningUrl();
            return <Redirect to={PAGES_URLS.auth} />;
        }

        return <PropsRoute {...this.props} />;
    }
}
