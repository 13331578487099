import IUserInfo from "./interfaces/IUserInfo";
import { UserInfoResponse } from "./types/UserInfoResponse";

export default class UserInfo implements IUserInfo {
    name = "";
    email = "";
    constructor({ name = "", email = "" }: UserInfoResponse) {
        this.name = name;
        this.email = email;
    }

    is3mUser = () => {
        return this.email.toLowerCase().endsWith("@mmm.com");
    };
}
