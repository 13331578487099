import { createStyles, Theme } from "@material-ui/core/styles";

export default (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        text: {
            color: "#212121",
            fontWeight: 700,
            lineHeight: "16px",
            letterSpacing: "-0.14px",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
        },
    });
