import { createStyles, Theme } from "@material-ui/core/styles";

export default (theme: Theme) =>
    createStyles({
        rootGrid: {
            height: "100%",
            flexDirection: "column",
            flexWrap: "nowrap",
        },
        toolbarGrid: {
            flexBasis: 36,
            width: "100%",
            margin: 0,
        },
        contentGrid: {
            margin: 0,
            width: "100%",
            height: "calc(100% - 60px)",
        },
        paper: {
            padding: theme.spacing(),
            wordBreak: "break-word",
            height: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
        },
        statusProcess: {
            color: "#fcca03",
            textShadow: "1px 1px 2px black",
        },
        statusComplete: {
            color: "#23d100",
            textShadow: "1px 1px 2px black",
        },
        statusFailed: {
            color: "#d40b0b",
            textShadow: "0.5px 0.5px 0px black",
        },
        message: {
            whiteSpace: "pre-wrap",
            overflow: "auto",
        },
        gridItemFirstLine: {
            height: "40%",
        },
        gridItemLastLine: {
            height: "60%",
        },
        settings: {
            whiteSpace: "pre-wrap",
        },
    });
