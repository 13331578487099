import React from "react";
import { Route, RouteProps } from "react-router-dom";

type Props<T = {}, P = {}> = {
    componentProps: T;
    component: React.ComponentType<T>;
} & Omit<RouteProps, "component">;

export default class PropsRoute<T = {}, P = {}> extends React.Component<Props<T, P>> {
    render() {
        const { componentProps, component: Component, ...routeProps } = this.props;
        return (
            <Route
                {...routeProps}
                render={routeProps => {
                    const finalProps = { ...componentProps, ...routeProps };
                    return <Component {...finalProps} />;
                }}
            />
        );
    }
}
