import { action, observable } from "mobx";

import { PAGES_URLS, STORAGE_KEYS } from "../../config";
import history from "../../history";
import { GOD_ROLE, HOSPITAL_GENK, NO_GROUP, REFRESH_SETTINGS } from "./constants";
import { ErrorTypes } from "./models/enums/ErrorTypes";
import { SignInState } from "./models/enums/SignInState";
import IIdentityStoreProps from "./models/interfaces/IIdentityStoreProps";
import ITokenInfo from "./models/interfaces/ITokenInfo";
import IUserInfo from "./models/interfaces/IUserInfo";
import TokenInfo from "./models/TokenInfo";
import { ErrorResponse } from "./models/types/ErrorResponse";
import UserInfo from "./models/UserInfo";

export default class Store {
    get accessToken() {
        return (this._tokenInfo && this._tokenInfo.accessToken) || undefined;
    }
    @observable userInfo?: IUserInfo;
    @observable currentSignInState = SignInState.SignIn;

    private _props: IIdentityStoreProps;
    private _tokenInfo: ITokenInfo | undefined;
    private get _identityApi() {
        return this._props.api;
    }
    private get _events() {
        const { onAuthenticate, onInit } = this._props;
        return {
            onAuthenticate,
            onInit,
        };
    }

    constructor(props: IIdentityStoreProps) {
        this._props = props;
    }

    @action load = async () => {
        try {
            const storedTokenJson = localStorage.getItem(STORAGE_KEYS.storedToken) || undefined;

            if (storedTokenJson) {
                const tokenInfo = TokenInfo.createFromJson(storedTokenJson);

                if (!tokenInfo.isExpired()) {
                    this._tokenInfo = tokenInfo;
                    this._events.onAuthenticate();
                    await this._loadUserInfo(tokenInfo);
                    this._isTokenAlive();
                } else {
                    this._clearUser();
                }
            }

            this._events.onInit();
        } catch (ex) {
            throw ex;
        }
    };

    @action loginOnFirstFactorAsync = async (email: string, password: string) => {
        try {
            const canSelectRoles = await this.signInAsync(email, password);
            if (!canSelectRoles) {
                throw new Error("This user is not an admin");
            }
            const rolesForSelect = await this.getRolesAsync(HOSPITAL_GENK);
            if (!rolesForSelect.includes(GOD_ROLE)) {
                throw new Error("This user is not an admin");
            }
            const { is2FAEnabled, hasAuthenticator } = await this.getTwoFactorParamsAsync(HOSPITAL_GENK, GOD_ROLE);

            if (is2FAEnabled) {
                if (hasAuthenticator) {
                    this.currentSignInState = SignInState.TwoFactor;
                } else {
                    throw new Error("Authenticator setup is required on Main Portal");
                }
            } else {
                const tokenInfo = await this.generateTokenAsync(HOSPITAL_GENK, NO_GROUP, GOD_ROLE);
                this._saveTokenInfo(tokenInfo);
                this._events.onAuthenticate();

                await this._loadUserInfo(tokenInfo);
                this._isTokenAlive();
            }
        } catch (error) {
            throw error;
        }
    };

    loginOnSecondFactorAsync = async (verificationCode: string) => {
        try {
            const tokenInfo = await this.generateTokenTwoFactorAsync(
                HOSPITAL_GENK,
                NO_GROUP,
                verificationCode,
                GOD_ROLE,
            );
            this._saveTokenInfo(tokenInfo);
            this._events.onAuthenticate();

            await this._loadUserInfo(tokenInfo);
            this._isTokenAlive();
        } catch (error) {
            throw error;
        }
    };

    @action logoutAsync = async () => {
        try {
            if (this.accessToken) {
                await this._identityApi.signOut(this.accessToken);
            }
        } finally {
            this._clearUser();
        }
    };

    signInAsync = async (email: string, password: string) => {
        try {
            return await this._identityApi.signIn(email, password);
        } catch (errors) {
            throw this._getErrorsForResponse(errors);
        }
    };

    getRolesAsync = async (hospitalKey: number) => {
        return await this._identityApi.getRoles(hospitalKey);
    };

    getTwoFactorParamsAsync = async (hospitalKey: number, selectedRole?: string) => {
        return await this._identityApi.getTwoFactorParams(hospitalKey, selectedRole);
    };

    generateTokenAsync = async (hospitalKey: number, hospitalGroupKey: number, selectedRole?: string) => {
        try {
            const tokenResponse = await this._identityApi.generateToken(hospitalKey, hospitalGroupKey, selectedRole);
            return TokenInfo.createFromResponse(tokenResponse);
        } catch (errors) {
            throw this._getErrorsForResponse(errors);
        }
    };

    generateTokenTwoFactorAsync = async (
        hospitalKey: number,
        hospitalGroupKey: number,
        verificationCode: string,
        selectedRole?: string,
    ) => {
        try {
            const tokenResponse = await this._identityApi.loginTwoFactor(
                hospitalKey,
                hospitalGroupKey,
                verificationCode,
                selectedRole,
            );
            return TokenInfo.createFromResponse(tokenResponse);
        } catch (errors) {
            throw this._getErrorsForResponse(errors);
        }
    };

    onToolbarButtonClick = () => {
        if (!!this.userInfo) {
            this.logoutAsync();
        } else {
            history.push(PAGES_URLS.auth);
        }
    };

    @action private _loadUserInfo = async (tokenInfo: ITokenInfo) => {
        try {
            const response = await this._identityApi.getUserInfo(tokenInfo.accessToken);
            const userInfo = new UserInfo(response);
            this.userInfo = userInfo;
        } catch (errors) {
            throw this._getErrorsForResponse(errors);
        }
    };

    @action private _clearUser = () => {
        localStorage.removeItem(STORAGE_KEYS.storedToken);
        this.userInfo = undefined;
        this._tokenInfo = undefined;
        this.currentSignInState = SignInState.SignIn;
    };

    private _isTokenAlive = async () => {
        if (this._tokenInfo) {
            if (!this._tokenInfo.isExpired()) {
                if (this._tokenInfo.willExpireInMs(REFRESH_SETTINGS.refreshAt)) {
                    const tokenInfo = await this._refreshTokenAsync(this._tokenInfo);
                    this._saveTokenInfo(tokenInfo);
                }

                setTimeout(this._isTokenAlive, REFRESH_SETTINGS.checkWindow);
            } else {
                this.logoutAsync();
            }
        }
    };

    private async _refreshTokenAsync(tokenInfo: ITokenInfo) {
        try {
            const tokenInfoResponse = await this._identityApi.refreshToken(tokenInfo.refreshToken);
            return TokenInfo.createFromResponse(tokenInfoResponse);
        } catch (ex) {
            throw ex;
        }
    }

    @action private _saveTokenInfo = (tokenInfo: ITokenInfo) => {
        this._tokenInfo = tokenInfo;
        localStorage.setItem(STORAGE_KEYS.storedToken, tokenInfo.toJSON());
    };

    private _getErrorsForResponse(errors: ErrorResponse[]) {
        const messages = this._parseErrorMessages(errors);
        return new Error(messages.join("<br/><br/>"));
    }

    private _parseErrorMessages = (errors: ErrorResponse[]) => {
        return errors.map(({ code, params }) => {
            switch (code) {
                case ErrorTypes.InvalidLoginPassword:
                    return "The user e-mail or password provided is incorrect.";
                case ErrorTypes.UserIsNotActivated:
                    return "This user cannot access application";
                case ErrorTypes.AccountBlocked:
                    const countMinutesBeforeUnlock = (params || {}).timeRemaining || 0;
                    return `Your account is temporary blocked. Please, try to sign in on the Portal after ${countMinutesBeforeUnlock} minute(s) or use the "Forgot password" option on Main Portal.`;
                case ErrorTypes.PasswordExpired:
                    return "Your password has expired and must be changed on Main Portal";
                case ErrorTypes.InvalidVerificationCode:
                    return "Entered verification code is not valid.";
                default:
                    return "Unknown error";
            }
        });
    };
}
