export enum ErrorTypes {
    OtherError = 1,
    InvalidLoginPassword = 2,
    AccountBlocked = 3,
    PasswordExpired = 4,
    InvalidVerificationCode = 6,
    UserIsNotActivated = 7,
    PasswordPolicyFailed = 8,
    PasswordsAreEqual = 9,
    RepearsInLast10Passwords = 10,
    IncorrectSymbolsInPassword = 11,
    PasswordShouldBeLonger = 12,
    ValidationDate = 13,
    No2FAEnabled = 14,
}
