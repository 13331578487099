import { BulkExportMethodsEnum } from "./enums/BulkExportMethodsEnum";
import { IBulkExportMethod } from "./types/IBulkExportMethod";

export default class BulkExportMethod implements IBulkExportMethod {
    private _bulkExportMethod: BulkExportMethodsEnum;

    constructor(bulkExportMethod: BulkExportMethodsEnum) {
        this._bulkExportMethod = bulkExportMethod;
    }

    getBulkExportMethodName() {
        switch (this._bulkExportMethod) {
            case BulkExportMethodsEnum.PerFiles:
                return "Per files";
            case BulkExportMethodsEnum.PerSheet:
                return "Per sheet";
        }
    }
}
