import ExportTaskStatus from "./ExportTaskStatus";
import { IExportTaskStatus } from "./types/IExportTaskStatus";
import { IExportTaskStatusChanges } from "./types/IExportTaskStatusChanges";
import { IExportTaskStatusChangesServ } from "./types/IExportTaskStatusChangesServ";

export default class ExportTaskStatusChanges implements IExportTaskStatusChanges {
    key: number;
    previousStatus: IExportTaskStatus;
    newStatus: IExportTaskStatus;
    dateChange: Date;

    constructor(exportTaskStatusChanges: IExportTaskStatusChangesServ) {
        this.key = exportTaskStatusChanges.fExportTask;
        this.previousStatus = new ExportTaskStatus(exportTaskStatusChanges.fPreviousStatus);
        this.newStatus = new ExportTaskStatus(exportTaskStatusChanges.fNewStatus);
        this.dateChange = new Date(exportTaskStatusChanges.fDateChange);
    }
}
