import { LocalesEnum } from "./enums/LocalesEnum";
import { ILocale } from "./types/ILocale";

export class Locale implements ILocale {
    private static mapValueToName = {
        [LocalesEnum.En]: "EN-BE",
        [LocalesEnum.Fr]: "FR-BE",
        [LocalesEnum.Nl]: "NL-BE",
    };
    private _locale: LocalesEnum;

    static getListOfLocalesForFilter() {
        return [
            this.mapValueToName[LocalesEnum.En],
            this.mapValueToName[LocalesEnum.Fr],
            this.mapValueToName[LocalesEnum.Nl],
        ];
    }

    static getValueFromName(name: string) {
        const entry = Object.entries(this.mapValueToName).find(it => name === it[1]);
        if (entry) {
            return entry[0] as LocalesEnum;
        }

        return undefined;
    }

    constructor(objectType: LocalesEnum) {
        this._locale = objectType;
    }

    getLocaleName() {
        const value = Locale.mapValueToName[this._locale];

        if (value === undefined) {
            return "Unknown type";
        }

        return value;
    }
}
