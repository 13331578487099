import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { mobxConnect, MobxConnectedComponent } from "../../../../mobxConnector";
import { Stores } from "../../store";
import ExportInfo from "./ExportInfo";
import GeneralInfo from "./GeneralInfo";
import MessageInfo from "./MessageInfo";
import StatusChangesInfo from "./StatusChangesInfo";
import StatusInfo from "./StatusInfo";
import style from "./style";

type Props = {
    canDownloadResults: boolean;
} & RouteComponentProps<{ exportTaskId: string }> &
    WithStyles<typeof style>;

@mobxConnect<Stores>("mainStore")
@observer
class TaskPage extends MobxConnectedComponent<Stores, Props> {
    get store() {
        return this.stores.mainStore;
    }

    render() {
        const {
            match: {
                params: { exportTaskId },
            },
            classes,
            canDownloadResults,
        } = this.props;
        return (
            <Grid container className={classes.rootGrid}>
                <Grid container item spacing={3} className={classes.toolbarGrid}>
                    <Grid item xs={4} className={classes.gridItemFirstLine}>
                        <Button color="primary" variant="contained" onClick={this.store.closeTab}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
                <Grid container item spacing={3} className={classes.contentGrid}>
                    <Grid item xs={4} className={classes.gridItemFirstLine}>
                        <Paper className={classes.paper}>
                            <StatusInfo exportTaskKey={Number(exportTaskId)} canDownloadResults={canDownloadResults} />
                        </Paper>
                    </Grid>
                    <Grid item xs={4} className={classes.gridItemFirstLine}>
                        <Paper className={classes.paper}>
                            <GeneralInfo exportTaskKey={Number(exportTaskId)} />
                        </Paper>
                    </Grid>
                    <Grid item xs={4} className={classes.gridItemFirstLine}>
                        <Paper className={classes.paper}>
                            <ExportInfo exportTaskKey={Number(exportTaskId)} />
                        </Paper>
                    </Grid>
                    <Grid item xs={4} className={classes.gridItemLastLine}>
                        <Paper className={classes.paper}>
                            <StatusChangesInfo exportTaskKey={Number(exportTaskId)} />
                        </Paper>
                    </Grid>
                    {this.stores.mainStore.exportTaskResult && this.stores.mainStore.exportTaskResult.message ? (
                        <Grid item xs={8} className={classes.gridItemLastLine}>
                            <Paper className={classes.paper}>
                                <MessageInfo exportTaskKey={Number(exportTaskId)} />
                            </Paper>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        );
    }
}

export default withRouter(withStyles(style)(TaskPage));
