import { Provider } from "mobx-react";
import React from "react";

import AppStore from "../store";
import { App } from "./App";

export default class Root extends React.Component {
    private _appStore: AppStore = new AppStore();

    render() {
        return (
            <Provider appStore={this._appStore}>
                <App />
            </Provider>
        );
    }
}
