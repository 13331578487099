import Button from "@material-ui/core/Button";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react";
import React from "react";

import Waiter from "../../../../components/Waiter";
import { mobxConnect, MobxConnectedComponent } from "../../../../mobxConnector";
import { Stores } from "../../store";
import style from "./style";

type Props = {
    exportTaskKey: number;
} & WithStyles<typeof style>;

@mobxConnect<Stores>("mainStore")
@observer
class MessageInfo extends MobxConnectedComponent<Stores, Props> {
    get store() {
        return this.stores.mainStore;
    }

    componentDidMount() {
        const { exportTaskKey } = this.props;
        this.store.loadTaskResult(exportTaskKey);
    }

    render() {
        const { exportTaskResult, loadingTaskResult } = this.store;

        if (loadingTaskResult) {
            return <Waiter />;
        }

        if (!exportTaskResult) {
            return null;
        }

        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Error message
                </Typography>
                <Typography variant="subtitle2" className={this.props.classes.message} gutterBottom>
                    {exportTaskResult.message}
                </Typography>
                <Button
                    onClick={this.onClickCopyToYoutrackButton}
                    color="primary"
                    variant="contained"
                    style={{ width: "fit-content" }}
                >
                    Copy for YouTrack
                </Button>
            </React.Fragment>
        );
    }

    onClickCopyToYoutrackButton = () => {
        this.store.copyMessageToClipboard();
    };
}

export default withStyles(style)(MessageInfo);
