import { createStyles, Theme } from "@material-ui/core/styles";

export default (theme: Theme) =>
    createStyles({
        submitButton: {
            marginLeft: "auto",
            textTransform: "none",
            minHeight: 32,
            width: 110,
        },
        errorContainer: {
            color: "red",
            minHeight: 28,
            fontSize: 13,
        },
        hrDivider: {
            marginBottom: 8,
        },
    });
