import Button from "@material-ui/core/Button";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { observer } from "mobx-react";
import React from "react";

import Waiter from "../../../../components/Waiter";
import { mobxConnect, MobxConnectedComponent } from "../../../../mobxConnector";
import { formatDateTime } from "../../helpers/DatesHelper";
import { Stores } from "../../store";
import LabelString from "./LabelString";
import style from "./style";

type Props = {
    exportTaskKey: number;
    canDownloadResults: boolean;
} & WithStyles<typeof style>;

@mobxConnect<Stores>("mainStore")
@observer
class StatusInfo extends MobxConnectedComponent<Stores, Props> {
    get store() {
        return this.stores.mainStore;
    }

    componentDidMount() {
        const { exportTaskKey } = this.props;
        this.store.loadTask(exportTaskKey);
        this.store.loadTaskResult(exportTaskKey);
    }

    render() {
        const { classes, canDownloadResults } = this.props;
        const { exportTask, exportTaskResult, loadingTasks, loadingTaskResult } = this.store;

        if (loadingTasks || loadingTaskResult) {
            return <Waiter />;
        }

        if (!exportTask) {
            return null;
        }

        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Status:{" "}
                    <span
                        className={clsx({
                            [classes.statusProcess]: exportTask.status.isProcess(),
                            [classes.statusComplete]: exportTask.status.isSuccess(),
                            [classes.statusFailed]: exportTask.status.isFailed(),
                        })}
                    >
                        {exportTask.status.getTextStatus()}
                    </span>
                </Typography>
                {exportTask.status.isFailed() ? (
                    <LabelString
                        label="Last successful status"
                        content={exportTask.lastSuccessfulStatus.getTextStatus()}
                    />
                ) : null}
                <LabelString label="Count Attempts" content={exportTask.attemptsCount.toString()} />
                <LabelString label="To be sent" content={formatDateTime(exportTask.date)} />
                {exportTaskResult ? (
                    <React.Fragment>
                        <LabelString
                            label={exportTaskResult.isHaveFile ? "Was result created" : "Was error created"}
                            content={formatDateTime(exportTaskResult.createDate)}
                        />
                        {exportTaskResult.isHaveFile && canDownloadResults ? (
                            <Button
                                onClick={this.onClickDownloadButton}
                                color="primary"
                                variant="contained"
                                style={{ width: "fit-content" }}
                            >
                                Download export result
                            </Button>
                        ) : null}
                    </React.Fragment>
                ) : null}
            </React.Fragment>
        );
    }

    onClickDownloadButton = () => {
        this.store.downloadTaskResult(this.props.exportTaskKey);
    };
}

export default withStyles(style)(StatusInfo);
