import {
    CellClassParams,
    ColDef,
    ColGroupDef,
    ICellRendererParams,
    ITooltipParams,
    ValueFormatterParams,
    ValueGetterParams,
} from "ag-grid-community";

import DictionaryManager from "../../../DictionaryManager";
import { HOSPITALS_DICTIONARY_ID, LOGINS_DICTIONARY_ID } from "../../constants";
import { formatDateTime } from "../../helpers/DatesHelper";
import { getHospitalNameByKey } from "../../helpers/HospitalsHelper";
import ExportTask from "../../models/ExportTask";
import ExportTaskStatus from "../../models/ExportTaskStatus";
import Format from "../../models/Format";
import { Locale } from "../../models/Locales";
import ObjectType from "../../models/ObjectType";
import { HospitalsDictionary } from "../../models/types/HospitalsDictionary";
import download from "../../../../icons/download.gif";

const defaultColumnsSettings: ColDef = {
    resizable: true,
    floatingFilter: true,
    sortable: true,
    filterParams: {
        buttons: ["clear"],
        suppressAndOrCondition: true,
    },
};

function getColumns(): (ColDef | ColGroupDef)[] {
    const hospDictionary = DictionaryManager.getDictionary<HospitalsDictionary>(HOSPITALS_DICTIONARY_ID);
    const hospitalsNames = hospDictionary ? Object.values(hospDictionary).map(it => it!.name) : [];
    return [
        {
            field: "key",
            headerName: "Key",
            width: 90,
            minWidth: 90,
            maxWidth: 90,
            filter: "agNumberColumnFilter",
            cellRenderer: keyCellRenderer,
            cellClass: "link-cell",
            tooltipField: "key",
        },
        {
            field: "queueName",
            headerName: "Queue",
            sortable: false,
            minWidth: 108,
        },
        {
            field: "attemptsCount",
            headerName: "Attempts",
            minWidth: 90,
        },
        {
            field: "exportTaskStatus",
            headerName: "Status",
            width: 200,
            minWidth: 130,
            valueGetter: statusValueGetter,
            tooltipValueGetter: statusTooltipValueGetter,
            cellClass: statusCellClass,
            filter: "agSetColumnFilter",
            filterParams: {
                ...defaultColumnsSettings.filterParams,
                values: ExportTaskStatus.getListOfStatusesForFilter(),
            },
            sortable: false,
        },
        {
            field: "name",
            headerName: "Name",
            filter: "agTextColumnFilter",
            tooltipField: "name",
            width: 250,
            minWidth: 115,
            autoHeight: true,
            cellStyle: {
                whiteSpace: "normal",
            },
            flex: 1,
        },
        {
            field: "format",
            headerName: "Format",
            filter: "agSetColumnFilter",
            valueGetter: formatValueGetter,
            filterParams: {
                ...defaultColumnsSettings.filterParams,
                values: Format.getListOfFormatsForFilter(),
            },
            width: 80,
            minWidth: 80,
            maxWidth: 80,
            sortable: false,
        },
        {
            field: "exportTaskType",
            headerName: "Type",
            valueGetter: typeValueGetter,
            width: 115,
            minWidth: 115,
            maxWidth: 115,
            filter: "agSetColumnFilter",
            filterParams: {
                ...defaultColumnsSettings.filterParams,
                values: ObjectType.getListOfStatusesForFilter(),
            },
            sortable: false,
        },
        {
            field: "login",
            headerName: "Login",
            filter: "agSetColumnFilter",
            tooltipField: "login",
            minWidth: 130,
            filterParams: {
                ...defaultColumnsSettings.filterParams,
                values: DictionaryManager.getDictionary(LOGINS_DICTIONARY_ID),
            },
        },
        {
            field: "exportTaskHospital",
            headerName: "Hospital",
            valueGetter: hospitalValueGetter,
            tooltipValueGetter: hospitalTooltipValueGetter,
            filter: "agSetColumnFilter",
            filterParams: {
                ...defaultColumnsSettings.filterParams,
                values: hospitalsNames,
            },
            minWidth: 130,
            sortable: false,
        },
        {
            field: "date",
            headerName: "Date to sent",
            filter: "agDateColumnFilter",
            filterParams: {
                ...defaultColumnsSettings.filterParams,
                comparator: dateFilterComparator,
            },
            valueFormatter: dateValueFormatter,
            width: 185,
            minWidth: 185,
            maxWidth: 185,
        },
        {
            field: "expectedTime",
            headerName: "Expected time to send",
            filter: "agDateColumnFilter",
            filterParams: {
                ...defaultColumnsSettings.filterParams,
                comparator: dateFilterComparator,
            },
            valueFormatter: dateValueFormatter,
            width: 195,
            minWidth: 195,
            maxWidth: 195,
        },
        {
            field: "sentEmailTime",
            headerName: "Completed",
            valueFormatter: dateValueFormatter,
            width: 195,
            minWidth: 195,
            maxWidth: 195,
            sortable: false,
        },
        {
            field: "exportTaskSubscription",
            headerName: "Subscription",
            valueGetter: subscriptionValueGetter,
            filter: "agNumberColumnFilter",
            filterParams: {
                ...defaultColumnsSettings.filterParams,
                filterOptions: ['equals'],
            },
            sortable: false,
            width: 115,
            minWidth: 115,
            maxWidth: 115,
        },
        {
            field: "locale",
            headerName: "Language",
            valueGetter: localeValueGetter,
            filter: "agSetColumnFilter",
            filterParams: {
                ...defaultColumnsSettings.filterParams,
                values: Locale.getListOfLocalesForFilter(),
            },
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            sortable: false,
        },
    ];
}

function typeValueGetter(params: ValueGetterParams) {
    if (!params.data) {
        return null;
    }
    const { objectType: objectKeyType } = params.data as ExportTask;

    return objectKeyType.getObjectTypeName();
}

function hospitalValueGetter(params: ValueGetterParams) {
    if (!params.data) {
        return null;
    }
    const { hospitalKey } = params.data as ExportTask;
    const hospitalName = getHospitalNameByKey(hospitalKey);
    if (!hospitalName) {
        return `${hospitalKey}`;
    }

    return hospitalName;
}

function hospitalTooltipValueGetter(params: ITooltipParams) {
    return params.value || "";
}

function dateValueFormatter(params: ValueFormatterParams) {
    if (!params.data) {
        return "";
    }
    const value: Date | undefined = params.value;
    if (!value) {
        return "-";
    }
    return formatDateTime(value);
}

function dateFilterComparator(filterValue: Date, value: Date) {
    const valueMidnight = new Date(value.getFullYear(), value.getMonth(), value.getDate());

    if (valueMidnight < filterValue) {
        return -1;
    } else if (valueMidnight > filterValue) {
        return 1;
    } else {
        return 0;
    }
}

function subscriptionValueGetter(params: ValueGetterParams) {
    if (!params.data) {
        return null;
    }
    const { subscriptionKey } = params.data as ExportTask;
    if (!subscriptionKey) {
        return "-";
    }
    return subscriptionKey;
}

function statusValueGetter(params: ValueGetterParams) {
    if (!params.data) {
        return null;
    }
    const { status } = params.data as ExportTask;
    return status.getTextStatus();
}

function statusTooltipValueGetter(params: ITooltipParams) {
    return params.value || "";
}

function statusCellClass(params: CellClassParams) {
    if (!params.data) {
        return "";
    }
    const { status } = params.data as ExportTask;
    let className: string = "";
    if (status.isExecuted()) {
        className = "yellow-cell";
    }
    if (status.isSuccess()) {
        className = "green-cell";
    }
    if (status.isFailed()) {
        className = "red-cell";
    }
    if (status.isUnknown()) {
        className = "grey-cell";
    }

    return className;
}

function keyCellRenderer(params: ICellRendererParams) {
    if (!params.value) {
        return `<img src="${download}">`;
    } else {
        return params.valueFormatted || params.value;
    }
}

function localeValueGetter(params: ValueGetterParams) {
    if (!params.data) {
        return null;
    }
    const { locale } = params.data as ExportTask;
    return locale.getLocaleName();
}

function formatValueGetter(params: ValueGetterParams) {
    if (!params.data) {
        return null;
    }
    const { format } = params.data as ExportTask;
    return format.getFormatName();
}

export default getColumns;
export { defaultColumnsSettings };
