import { IGroup } from "./types/IGroup";
import { IGroupServ } from "./types/IGroupServ";

export default class Group implements IGroup {
    key: number;
    name: string;

    constructor(servGroup: IGroupServ) {
        this.key = servGroup.key;
        this.name = servGroup.name;
    }
}
