import { FormControl, FormHelperText } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import * as React from "react";
import { FieldRenderProps } from "react-final-form";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import style from "./style";
import Input from "@material-ui/core/Input/Input";

type OwnProps = TextFieldProps & WithStyles<typeof style>;

type Props<FieldValue> = FieldRenderProps<FieldValue, HTMLElement> & OwnProps;

class InputField extends React.Component<Props<string | number | boolean>> {
    render() {
        const {
            input,
            meta,
            label,
            disabled,
            type,
            placeholder,
            rows,
            multiline,
            autoFocus,
            autoComplete = "off",
            tabIndex,
            classes,
        } = this.props;
        const { name, value } = input;
        const { touched, error, invalid, submitError } = meta;
        const isError = touched && (!!error || !!invalid);
        const classNames = [classes.input];
        if (isError) {
            classNames.push(classes.error);
        }

        return (
            <FormControl error={isError} fullWidth disabled={disabled} margin={label ? "dense" : "none"}>
                {label && <FormHelperText>{label}</FormHelperText>}
                <Input
                    value={value}
                    name={name}
                    autoComplete={autoComplete}
                    autoFocus={autoFocus}
                    onChange={this._onChange}
                    inputProps={{
                        type,
                        tabIndex,
                        placeholder,
                        className: classNames.join(" "),
                    }}
                    multiline={multiline}
                    rows={rows}
                    disabled={disabled}
                />
                {isError && <FormHelperText>{error || submitError}</FormHelperText>}
            </FormControl>
        );
    }

    private _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onChange, input } = this.props;
        input.onChange(event.target.value);

        if (onChange) {
            onChange(event);
        }
    };
}

export default withStyles(style)(InputField);
