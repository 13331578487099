import Axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export enum InstancesIds {
    Base = "base",
    ExportManager = "exportManager",
    Identity = "identity",
}

export default class AxiosInstanceBuilder {
    private static instances: { [instaceId: string]: AxiosInstance | undefined } = {};
    private _getToken: () => string | undefined;
    private _onUnauthorized: () => void;

    constructor(getToken: () => string | undefined, onUnauthorized: () => void) {
        this._getToken = getToken;
        this._onUnauthorized = onUnauthorized;
    }

    createInstance(instanceId: InstancesIds, baseURL?: string): AxiosInstance {
        let instance = this._getInstance(instanceId);
        if (!!instance) {
            return instance;
        }
        instance = Axios.create({ ...this._getBaseAxiosConfig(), baseURL });
        this._setInterceptorsForInstance(instance);
        this._addInstance(instanceId, instance);
        return instance;
    }

    private _addInstance(instanceId: InstancesIds, axiosInstance: AxiosInstance) {
        AxiosInstanceBuilder.instances[instanceId] = axiosInstance;
    }

    private _getInstance(instanceId: InstancesIds): AxiosInstance | undefined {
        return AxiosInstanceBuilder.instances[instanceId];
    }

    private _getBaseAxiosConfig(): AxiosRequestConfig {
        return {};
    }

    private _setInterceptorsForInstance(instance: AxiosInstance): void {
        instance.interceptors.request.use(
            config => {
                const { headers } = config;
                const isHaveTokenInConfig = (headers && !!headers.Authorization) || false;
                const token = this._getToken();

                config.headers = {
                    ...(config.headers || {}),
                    ...(!isHaveTokenInConfig &&
                        token && {
                            Authorization: `Bearer ${token}`,
                        }),
                };

                return config;
            },
            error => {
                return Promise.reject(error);
            },
        );
        instance.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (error && error.response && error.response.status === 401) {
                    this._onUnauthorized();
                }
                return Promise.reject(error);
            },
        );
    }
}
