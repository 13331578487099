export default class DictionaryManager {
    private static _dictionaries: { [dictionaryId: string]: any } = {};

    static addDictionary<T>(dictionaryId: string, dictionary: T, forceRewrite: boolean = false) {
        if (!this._dictionaries[dictionaryId] || forceRewrite) {
            this._dictionaries[dictionaryId] = dictionary;
        }
    }

    static getDictionary<T>(dictionaryId: string): T | undefined {
        return this._dictionaries[dictionaryId];
    }
}
