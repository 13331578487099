import { FormatsEnum } from "./enums/FormatsEnum";
import { IFormat } from "./types/IFormat";

export default class Format implements IFormat {
    private static mapValueToName = {
        [FormatsEnum.Excel]: "Excel",
        [FormatsEnum.PowerPoint]: "Power point",
        [FormatsEnum.Pdf]: "PDF",
        [FormatsEnum.Zip]: "Zip-archive",
        [FormatsEnum.ZipPdf]: "Zip-archive with PDF",
        [FormatsEnum.ZippedImages]: "Zip-archive with images",
        [FormatsEnum.WhatsNewPdf]: "PDF What's new",
    };
    private _format: FormatsEnum;

    static getListOfFormatsForFilter() {
        return [
            this.mapValueToName[FormatsEnum.Excel],
            this.mapValueToName[FormatsEnum.PowerPoint],
            this.mapValueToName[FormatsEnum.Pdf],
        ];
    }

    static getValueFromName(name: string) {
        const entry = Object.entries(this.mapValueToName).find(it => name === it[1]);
        if (entry) {
            return Number(entry[0]) as FormatsEnum;
        }

        return undefined;
    }

    constructor(formatKey: FormatsEnum) {
        this._format = formatKey;
    }

    getFormatName() {
        const value = Format.mapValueToName[this._format];

        if (value === undefined) {
            return "Unknown format";
        }

        return value;
    }
}
