import ExportTaskStatus from "./ExportTaskStatus";
import Format from "./Format";
import Hospital from "./Hospital";
import { Locale } from "./Locales";
import ObjectType from "./ObjectType";

export default class FilterSetMapping {
    static getValueFromFilter(columnId: string, value: string): number | string | undefined {
        switch (columnId) {
            case "exportTaskStatus":
                return ExportTaskStatus.getValueFromName(value);
            case "exportTaskType":
                return ObjectType.getValueFromName(value);
            case "locale":
                return Locale.getValueFromName(value);
            case "exportTaskHospital":
                return Hospital.getValueFromName(value);
            case "format":
                return Format.getValueFromName(value);
            default:
                return value;
        }
    }
}
