import { IDatasource, IGetRowsParams } from "ag-grid-community";

import Store from "./store";

export default class TableDataSource implements IDatasource {
    rowCount?: number;
    private _store: Store;

    constructor(store: Store) {
        this._store = store;
    }

    getRows(params: IGetRowsParams): void {
        this._store.loadTasks(params);
    }
}
