import { createStyles, Theme } from "@material-ui/core/styles";

export default (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    });
