import { createStyles, Theme } from "@material-ui/core/styles";

export default (theme: Theme) =>
    createStyles({
        input: {
            "&::-ms-clear": {
                display: "none",
            },
            "&::-ms-reveal": {
                display: "none",
            },
        },
        error: {
            border: "1px solid #f44336",
        },
    });
