import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import React from "react";

import { INavigationElement } from "./INavigationElement";
import style from "./style";
import { ToolbarAuthPanel } from "../../../modules/Identity";
import history from "../../../history";

type Props = {
    className?: string;
    navElements: INavigationElement[];
    currentUrl: string;
} & WithStyles<typeof style>;

type State = {
    isOpened: boolean;
};

class NavigationPanel extends React.Component<Props, State> {
    state: State = {
        isOpened: false,
    };

    render() {
        const { classes, navElements, currentUrl, className } = this.props;
        const { isOpened } = this.state;
        return (
            <div className={clsx(classes.root, className)}>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: isOpened,
                        [classes.drawerClose]: !isOpened,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: isOpened,
                            [classes.drawerClose]: !isOpened,
                        }),
                    }}
                    transitionDuration={5000}
                >
                    <ToolbarAuthPanel />
                    <List>
                        {navElements.map(({ url, icon: Icon, name }) => (
                            <ListItem
                                key={url}
                                id={url}
                                button
                                selected={url === currentUrl}
                                onClick={this.onClickListItem}
                            >
                                <ListItemIcon>
                                    <Icon />
                                </ListItemIcon>
                                <ListItemText primary={name} />
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
                <IconButton onClick={this.onClickIconButton} color="primary" className={classes.button} size="small">
                    {!isOpened ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </div>
        );
    }

    onClickIconButton = () => {
        this.setState(prevState => ({ isOpened: !prevState.isOpened }));
    };

    onClickListItem = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { currentUrl } = this.props;
        const clickedLink = e.currentTarget.id;

        if (currentUrl !== clickedLink) {
            history.push(clickedLink);
        }
    };
}

export default withStyles(style)(NavigationPanel);
