import { StatusesEnum } from "./enums/StatusesEnum";
import { IExportTaskStatus } from "./types/IExportTaskStatus";

export default class ExportTaskStatus implements IExportTaskStatus {
    private static mapValueToName = {
        [StatusesEnum.Ready]: "Ready for export",
        [StatusesEnum.ReadyForExport]: "Ready for export",
        [StatusesEnum.PreCreateGetExpectedTime]: "Get expected time",
        [StatusesEnum.ExportClientProcess]: "Export in tramplukator",
        [StatusesEnum.ExportClientFailed]: "Failed export in tramplukator",
        [StatusesEnum.ExportClientSuccess]: "Success export in tramplukator",
        [StatusesEnum.ExportFileProcess]: "Creating export file",
        [StatusesEnum.ExportFileFailed]: "Failed creating export file",
        [StatusesEnum.ExportFileSuccess]: "Success creating export file",
        [StatusesEnum.ExportSuccess]: "Success export",
        [StatusesEnum.SendResultProcess]: "Sending export file",
        [StatusesEnum.SendResultFailed]: "Failed sending export file",
        [StatusesEnum.SendResultSuccess]: "Sucess sending export file",
        [StatusesEnum.ExportSended]: "Export sended",
        [StatusesEnum.Failed]: "Failed export or sending",
    };
    private _status: StatusesEnum;

    static getListOfStatusesForFilter() {
        const statuses: StatusesEnum[] = [
            StatusesEnum.Ready,
            StatusesEnum.ReadyForExport,
            StatusesEnum.ExportClientProcess,
            StatusesEnum.ExportFileProcess,
            StatusesEnum.ExportSuccess,
            StatusesEnum.SendResultProcess,
            StatusesEnum.ExportSended,
            StatusesEnum.Failed,
        ];
        return statuses.map(it => `${it.toString()} - ${this.mapValueToName[it]}`);
    }

    static getValueFromName(name: string) {
        const entry = Object.entries(this.mapValueToName).find(it => name === `${it[0]} - ${it[1]}`);
        if (entry) {
            return Number(entry[0]) as StatusesEnum;
        }

        return undefined;
    }

    constructor(status: StatusesEnum) {
        this._status = status;
    }

    getTextStatus() {
        const value = ExportTaskStatus.mapValueToName[this._status];
        const description = value === undefined ? "Unknown status" : value;

        return `${this._status} - ${description}`;
    }

    getKey() {
        return this._status;
    }

    isProcess() {
        return [
            StatusesEnum.Ready,
            StatusesEnum.ReadyForExport,
            StatusesEnum.ExportClientProcess,
            StatusesEnum.ExportClientSuccess,
            StatusesEnum.ExportFileProcess,
            StatusesEnum.ExportFileSuccess,
        ].includes(this._status);
    }

    isExecuted() {
        return [StatusesEnum.ExportSuccess, StatusesEnum.SendResultProcess].includes(this._status);
    }

    isSuccess() {
        return [StatusesEnum.ExportSended].includes(this._status);
    }

    isFailed() {
        return [StatusesEnum.Failed].includes(this._status);
    }

    isUnknown() {
        return !this.isProcess() && !this.isExecuted() && !this.isSuccess() && !this.isFailed();
    }
}
