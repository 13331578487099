import { Provider } from "mobx-react";
import React from "react";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";

import { IExportTaskTrackerApi } from "../../models/types/IExportTaskTrackerApi";
import Store from "../../store";
import TaskPage from "../TaskPage";
import TasksListPage from "../TasksListPage";
import { PropsRoute } from "../../../../components/Routes";

interface Props extends RouteComponentProps<{}> {
    api: IExportTaskTrackerApi;
    canDownloadResults: boolean;
}

class Router extends React.Component<Props> {
    private _store: Store;
    constructor(props: Props) {
        super(props);
        this._store = new Store(props.api);
    }

    render() {
        const {
            match: { url },
            canDownloadResults,
        } = this.props;
        return (
            <Provider mainStore={this._store}>
                <Switch>
                    <PropsRoute
                        path={`${url}/:exportTaskId`}
                        component={TaskPage}
                        componentProps={{ canDownloadResults }}
                    />
                    <Route path={url} component={TasksListPage} />
                </Switch>
            </Provider>
        );
    }

    componentWillUnmount() {
        delete this._store;
    }
}

export default withRouter(Router);
