import { AxiosInstance } from "axios";
import { action, IReactionDisposer, observable, reaction } from "mobx";

import AxiosInstanceBuilder, { InstancesIds } from "./api/AxiosInstanceBuilder";
import ExportTaskTrackerApi from "./api/ExportTaskTrackerApi";
import IdentityApi from "./api/IdentityApi";
import SettingsApi from "./api/SettingsApi";
import { PAGES_URLS, STORAGE_KEYS } from "./config";
import history from "./history";
import { IExportTaskTrackerApi } from "./modules/ExportTaskTracker";
import { IdentityStore } from "./modules/Identity";
import { SettingsStore } from "./modules/Settings";

export default class Store {
    @observable isInitializing: boolean = false;
    @observable isInited: boolean = false;
    @observable exportTaskTrackerApi: IExportTaskTrackerApi | undefined;
    settingsStore: SettingsStore = new SettingsStore(new SettingsApi());
    identityStore: IdentityStore | undefined;

    private _axiosInstanceBuilder: AxiosInstanceBuilder | undefined;
    private _baseInstance: AxiosInstance | undefined;
    private _identityInstance: AxiosInstance | undefined;
    private _exportManagerInstance: AxiosInstance | undefined;
    private _onLoadSettingsReactionDisposer: IReactionDisposer;

    constructor() {
        this._onLoadSettingsReactionDisposer = reaction(
            () => this.settingsStore.isLoadedSettings === true,
            this._onLoadSettings,
        );
    }

    @action init = () => {
        this.isInitializing = true;
        this.settingsStore.loadSettings();
    };

    getToken = () => {
        return this.identityStore && this.identityStore.accessToken;
    };

    saveReturningUrl = () => {
        if (window.location.pathname !== "/") {
            sessionStorage.setItem(STORAGE_KEYS.returningUrl, window.location.pathname);
        }
    };

    redirectToAuthPage = () => {
        history.push(PAGES_URLS.auth);
    };

    @action private _onLoadSettings = () => {
        this._onLoadSettingsReactionDisposer();
        const settings = this.settingsStore.settings!;
        this._axiosInstanceBuilder = new AxiosInstanceBuilder(this.getToken, this._onUnauthorized);
        this._baseInstance = this._axiosInstanceBuilder.createInstance(InstancesIds.Base, "/");
        this._identityInstance = this._axiosInstanceBuilder.createInstance(
            InstancesIds.Identity,
            settings.endpoints.identity,
        );
        this._exportManagerInstance = this._axiosInstanceBuilder.createInstance(
            InstancesIds.ExportManager,
            settings.endpoints.exportManager,
        );
        this.exportTaskTrackerApi = new ExportTaskTrackerApi({
            baseInstanceApi: this._baseInstance,
            identityInstanceApi: this._identityInstance,
            exportManagerInstanceApi: this._exportManagerInstance,
        });

        this._initIdentity(this._identityInstance);
    };

    private _initIdentity = (identityInstance: AxiosInstance) => {
        this.identityStore = new IdentityStore({
            api: new IdentityApi(identityInstance),
            onInit: this._onAuthInited,
            onAuthenticate: this._onAuthenticate,
        });
        this.identityStore.load();
    };

    @action private _onAuthInited = () => {
        this.isInitializing = false;
        this.isInited = true;
    };

    private _onAuthenticate = () => {
        const returningUrl = sessionStorage.getItem(STORAGE_KEYS.returningUrl);
        if (returningUrl) {
            sessionStorage.removeItem(STORAGE_KEYS.returningUrl);
            history.push(returningUrl);
        }
        if (window.location.pathname === PAGES_URLS.auth) {
            history.push("/");
        }
    };

    private _onUnauthorized = () => {
        const promise = this.identityStore && this.identityStore.logoutAsync();

        if (promise) {
            promise.then(() => history.push("/"));
        }
    };
}
