import { createStyles, Theme } from "@material-ui/core/styles";

export default (theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            width: "100%",
            display: "flex",
            minWidth: 1024,
            minHeight: 500,
        },
        navPanel: {
            height: "100%",
            position: "absolute",
        },
        content: {
            width: "100%",
            marginLeft: theme.spacing(7) + 1,
            height: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(9) + 1,
            },
            padding: theme.spacing(3),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    });
