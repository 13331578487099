import CssBaseline from "@material-ui/core/CssBaseline";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import ListIcon from "@material-ui/icons/List";
import React from "react";

import NavigationPanel from "./NavigationPanel";
import style from "./style";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { PAGES_URLS } from "../../config";

type Props = RouteComponentProps<{}> & WithStyles<typeof style>;

type State = {
    isOpenedDrawer: boolean;
};

class Layout extends React.Component<Props, State> {
    state: State = {
        isOpenedDrawer: true,
    };

    render() {
        const {
            classes,
            children,
            history: {
                location: { pathname },
            },
        } = this.props;

        return (
            <div className={classes.root}>
                <CssBaseline />
                <NavigationPanel
                    navElements={[{ url: PAGES_URLS.exportTaskTracker, name: "Export Task Tracker", icon: ListIcon }]}
                    currentUrl={pathname}
                    className={classes.navPanel}
                />
                <main className={classes.content}>{children}</main>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(Layout));
