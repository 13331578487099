import DictionaryManager from "../../DictionaryManager";
import { HOSPITALS_DICTIONARY_ID } from "../constants";
import { HospitalsDictionary } from "../models/types/HospitalsDictionary";

export function getHospitalNameByKey(hospitalKey: number): string | undefined {
    const hospitalsDictionary = DictionaryManager.getDictionary<HospitalsDictionary>(HOSPITALS_DICTIONARY_ID);
    if (!hospitalsDictionary) {
        return undefined;
    }
    const hospital = hospitalsDictionary[hospitalKey];
    if (!hospital) {
        return undefined;
    }
    return hospital.name;
}
