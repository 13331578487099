import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import React from "react";

import style from "./style";

type Props = WithStyles<typeof style>;

class Waiter extends React.Component<Props> {
    render() {
        const { classes } = this.props;

        return (
            <Box className={classes.root}>
                <CircularProgress />
            </Box>
        );
    }
}

export default withStyles(style)(Waiter);
