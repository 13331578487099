import Button from "@material-ui/core/Button/Button";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import * as React from "react";
import { Field, Form, FormRenderProps } from "react-final-form";

import style from "./style";
import InputField from "../../../../../components/InputField";
import { removeSpacesFromVerificationCode } from "../utils";
import { VerificationCodeFormData } from "./types";
import { FormValidateErrors } from "../../../models/types/FormValidateErrors";

type Props = {
    onSubmit: (values: VerificationCodeFormData) => void;
} & WithStyles<typeof style>;

class VerificationCodeForm extends React.Component<Props> {
    render() {
        const { onSubmit } = this.props;

        return (
            <Form<VerificationCodeFormData> onSubmit={onSubmit} validate={this.validator} render={this.renderForm} />
        );
    }

    renderForm = (props: FormRenderProps<VerificationCodeFormData>) => {
        const { submitError, handleSubmit } = props;
        const { classes } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Field name="verificationCode">
                    {fieldProps => (
                        <InputField
                            {...fieldProps}
                            type="text"
                            tabIndex={0}
                            label={"Enter verification code"}
                            fullWidth
                            autoFocus
                        />
                    )}
                </Field>
                <div className={classes.errorContainer}>{submitError}</div>
                <Button className={classes.submitButton} type="submit" variant="contained" color="primary" tabIndex={3}>
                    Continue
                </Button>
            </form>
        );
    };

    validator = (values: VerificationCodeFormData) => {
        const { verificationCode } = values;
        const errors: FormValidateErrors<VerificationCodeFormData> = {};

        if (!verificationCode) {
            errors.verificationCode = "Verification code is required";
        } else {
            const codeLength = removeSpacesFromVerificationCode(verificationCode).length;
            const isValidAsRecoveryCode = codeLength === 8;
            const isValidAsAuthenticatorCode = codeLength === 6;
            if (!isValidAsRecoveryCode && !isValidAsAuthenticatorCode) {
                errors.verificationCode = "Length of verify code should be 6 symbols";
            }
        }

        return errors;
    };
}

export default withStyles(style)(VerificationCodeForm);
