export interface IEndpointsServ {
    identity: string;
    primary: string;
    exporting: string;
    exportManager: string;
    dataImport: string;
    search: string;
    notifications: string;
    reporting: string;
    exportTaskTracker: string;
}

export default class Endpoints {
    identity: string;
    primary: string;
    exporting: string;
    exportManager: string;
    dataImport: string;
    search: string;
    notifications: string;
    reporting: string;
    exportTaskTracker: string;

    constructor(endPoints: IEndpointsServ) {
        this.dataImport = endPoints.dataImport;
        this.primary = endPoints.primary;
        this.exportManager = endPoints.exportManager;
        this.exportTaskTracker = endPoints.exportTaskTracker;
        this.identity = endPoints.identity;
        this.exporting = endPoints.exporting;
        this.notifications = endPoints.notifications;
        this.reporting = endPoints.reporting;
        this.search = endPoints.search;
    }
}
